$buybox-margin-top: 36px;
$buybox-label-margin-bottom: 5px;
$buybox-box-top-margin: 20px;
$buybox-box-bottom-margin: 20px;
$buybox-store-description-margin-bottom: 8px;
$buybox-add-to-cart-extra-actions-margin: 8px;
$buybox-add-to-cart-extra-actions-width: 48px;
$buybox-product-status-padding-top: 18px;
$buybox-quantity-input: 5px;
$buybox-incr-decr: 5px;
$gutter-width: 40px;
$media-gallery-width:66%;
$msv-dialog-width: 400px;
$msv-dialog-modal-content-padding: 20px;
$msv-dialog-modal-content-body-text-padding: 4px 0;
$msv-dialog-modal-content-empty-image-padding-bottom: 10px;
$msv-dialog-modal-content-button-margin: 6px 0;
$msv-dialog-button-width: 100%;
$msv-dialog-msc-empty_image-size: 240px;
$msv-icon-margin-right: 8px;
$buybox-custom-amount-input-width: 80px;
$buybox-custom-amount-input-padding:5px;

//style presets
:root {
    --msv-buybox-btn-bg: #{$msv-white};
    --msv-buybox-btn-font-color: var(--msv-font-primary-color);
    --msv-buybox-btn-border-color: var(--msv-border-color);
    --msv-buybox-btn-font-size: var(--msv-body-font-size-l);

    //title
    --msv-buybox-title-font-size: var(--msv-body-font-size-xxl);
    --msv-buybox-title-font-color: var(--msv-font-primary-color);

    // rating star
    --msv-buybox-rating-star: var(--msv-font-primary-color);
    --msv-buybox-btn-extra-actions-color: var(--msv-accent-brand-color);

    // wishlist button
    --msv-buybox-btn-wishlist-color: var(--msv-accent-brand-color);

    //text
    --msv-buybox-text-font-size: var(--msv-body-font-size-m);
    --msv-buybox-text-font-color: var(--msv-font-primary-color);

    // primary button
    --msv-buybox-primary-btn-bg: var(--msv-accent-brand-color);
    --msv-buybox-primary-btn-font-color: var(--msv-font-secondary-color);
    --msv-buybox-primary-btn-border: var(--msv-accent-brand-color);

    // secondary button
    --msv-buybox-secondary-btn-bg: var(--msv-secondary-button-background-color);
    --msv-buybox-secondary-btn-font-color: var(--msv-font-primary-color);
    --msv-buybox-secondary-btn-border: var(--msv-accent-brand-color);

    // Flippers
    --msv-buybox-carousel-flipper-color: #{$msv-gray-900};
    --msv-buybox-carousel-flipper-hover-color: #{$msv-gray-800};
}

.ms-buybox {
    margin-top: $buybox-margin-top;
    display: flex;
    flex-flow: row;

    &__inventory-info {
        margin-top: $buybox-box-top-margin;
    }

    &__product-title {
        @include font-content(var(--msv-font-weight-light), var(--msv-buybox-title-font-size), $msv-line-height-xxl);
        color: var(--msv-buybox-title-font-color);
        margin-bottom: 8px;
    }

    &__inventory-label {
        @include font-content(var(--msv-font-weight-normal), var(--msv-body-font-size-l), $msv-line-height-m);
    }

    &__product-description {
        @include font-content(var(--msv-font-weight-light), var(--msv-buybox-text-font-size), $msv-line-height-m);
        color: var(--msv-buybox-text-font-color);
        margin-top: $buybox-box-top-margin;
        margin-bottom: $buybox-box-bottom-margin;
    }

    .msc-rating {
        &__count,
        &__star {
            color: var(--msv-buybox-rating-star);
        }

        &__half-star {
            &::after {
                color: var(--msv-buybox-rating-star);
            }
        }
    }

    .msc-price__actual {
        @include font-content-xl();
        color: var(--msv-buybox-text-font-color);
    }

    .msc-price__strikethrough {
        @include font-content-xl(var(--msv-font-weight-light));
    }

    &__configure {
        @include font-content-l(var(--msv-font-weight-normal));
    }

    &__dropdown {
        margin-top: 20px;

        &-quantity-label {
            @include font-content-l(var(--msv-font-weight-light));
            color: var(--msv-buybox-title-font-color);
        }
    }

    &__media-gallery {
        display: block;
        flex-basis: $media-gallery-width;
        min-width: $media-gallery-width;
        flex-grow: 1;
        flex-wrap: nowrap;
        padding-right: $gutter-width;
    }

    .msc-dropdown__select {
        @include form-control();
    }

    .quantity {
        .quantity-input {
            @include form-control();
            width: 80px;
            display: inline-block;
            margin-left: 8px;
            margin-right: 8px;
            -moz-appearance: textfield;
            padding: $buybox-quantity-input;
            vertical-align: bottom;

            &::-webkit-inner-spin-button,
            &::-webkit-outer-spin-button {
                -webkit-appearance: none;
            }
        }

        .quantity__controls {
            padding: $buybox-incr-decr;
            padding-left: 0;
            padding-right: 0;
            background: var(--msv-buybox-btn-bg);
            background-position: center;
            background-repeat: no-repeat;
            border: 1px solid var(--msv-buybox-btn-border-color);
            min-width: auto;
            width: 48px;
            height: 48px;
            cursor: pointer;

            &:disabled {
                color: $msv-gray-300;
            }
        }

        .increment {
            @include add-icon($msv-Add, after);
            color: var(--msv-buybox-btn-font-color);

            &.disabled {
                color: $msv-gray-300;
            }

            &:not(:disabled):not(.disabled):active,
            &:not(:disabled):not(.disabled):active:focus {
                color: var(--msv-buybox-btn-font-color);
            }
        }

        .decrement {
            @include add-icon($msv-Remove, after);
            color: var(--msv-buybox-btn-font-color);

            &.disabled {
                color: $msv-gray-300;
            }

            &:not(:disabled):not(.disabled):active,
            &:not(:disabled):not(.disabled):active:focus {
                color: var(--msv-buybox-btn-font-color);
            }
        }
    }

    &__quantity {
        margin-top: 20px;
    }

    &__product-quantity-label {
        @include font-content-l(var(--msv-font-weight-light));
        color: var(--msv-buybox-title-font-color);
        display: block;
        margin-bottom: $buybox-label-margin-bottom;
    }

    .ms-buybox__key_in_price {
        margin-top: 20px;

        .ms-buybox__key_in_price-label {
            @include font-content-l(var(--msv-font-weight-light));
            color: var(--msv-buybox-title-font-color);
            display: block;
            margin-bottom: $buybox-label-margin-bottom;
        }

        .ms-buybox__key_in_price_custom-amount__input {
            @include form-control();
            -moz-appearance: textfield;
            padding: $buybox-custom-amount-input-padding;
            text-align: left;

            &::-webkit-inner-spin-button,
            &::-webkit-outer-spin-button {
                -webkit-appearance: none;
            }
        }
    }

    .ms-text-block {
        margin-top: 0;
        margin-bottom: 1rem;

        @include font-content(var(--msv-font-weight-normal),
        var(--msv-buybox-text-font-size), $msv-line-height-m);
    }

    .product-add-to-cart {
        margin-top: 20px;

        .buttons {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
        }

        .msc-add-to-cart {
            @include primary-button(var(--msv-buybox-primary-btn-bg),
            var(--msv-buybox-primary-btn-font-color), var(--msv-buybox-primary-btn-border));
            font-size: var(--msv-buybox-btn-font-size);
            line-height: $msv-line-height-l;
            flex-grow: 1;
        }

        .ms-wishlist-items__product-status {
            width: 100%;
            padding-top: $buybox-product-status-padding-top;
        }

        .msc-add-to-cart-extra-actions {
            @include font-glyph-standard();
            @include secondary-button(var(--msv-buybox-secondary-btn-bg),
            var(--msv-buybox-secondary-btn-font-color), var(--msv-buybox-secondary-btn-border));
            color: var(--msv-buybox-btn-extra-actions-color);
            padding-left: 0;
            padding-right: 0;
            position: relative;
            min-width: $buybox-add-to-cart-extra-actions-width;
            width: $buybox-add-to-cart-extra-actions-width;
            flex: 0 0 $buybox-add-to-cart-extra-actions-width;
            margin-left: $buybox-add-to-cart-extra-actions-margin;

            &:not(:disabled):hover,
            &:not(:disabled):not(.disabled):active:focus {
                color: var(--msv-buybox-btn-extra-actions-color);
                border-color: transparent;
            }
        }

        .msc-add-to-wishlist {
            color: var(--msv-buybox-btn-wishlist-color);
        }
    }

    &__find-in-store {
        margin-top: 20px;
        margin-bottom: 20px;
        width: auto;
    }

    &__find-in-store-heading {
        @include font-content-l(var(--msv-font-weight-light));
    }

    &__find-in-store-description {
        margin-top: 8px;
        margin-bottom: $buybox-store-description-margin-bottom;

        @include font-content-s();
    }

    &__find-in-store-pickup-option {
        margin-top: 8px;
        margin-bottom: $buybox-store-description-margin-bottom;

        @include font-content-s();
        @include add-icon($msv-Checkbox-Circle-Checked, before);

        &::before {
            margin-right: 10px;
            color: --msv-buybox-primary-btn-font-color;
        }
    }

    &__minmax_label_text {
        margin-top: 12px;
        font-weight: var(--msv-font-weight-bold);

        .msi-InfoCircle {
            @include add-icon($msv-InfoCircle);
            margin-right: $msv-icon-margin-right;
        }
    }

    &__find-in-store-button {
        @include button-default();
        @include secondary-button(var(--msv-buybox-secondary-btn-bg),
        var(--msv-buybox-secondary-btn-font-color), var(--msv-buybox-secondary-btn-border));
        font-size: var(--msv-buybox-btn-font-size);
        line-height: $msv-line-height-l;
    }

    &__shopsimilarlooks {
        margin-top: $buybox-box-top-margin;
    }

    &__shop-similar-looks-button {
        @include button-default();
        @include secondary-button(
            var(--msv-buybox-secondary-btn-bg),
            var(--msv-buybox-secondary-btn-font-color),
            var(--msv-buybox-secondary-btn-border));
        font-size: var(--msv-buybox-btn-font-size);
        line-height: $msv-line-height-l;
        margin-bottom: 20px;
    }

    &__shop-similar-descriptions-button {
        @include button-default();
        @include secondary-button(
            var(--msv-buybox-secondary-btn-bg),
            var(--msv-buybox-secondary-btn-font-color),
            var(--msv-buybox-secondary-btn-border));
        font-size: var(--msv-buybox-btn-font-size);
        line-height: $msv-line-height-l;
    }

    &__dialog {
        max-width: $msv-dialog-width;

        .msc-modal__content {
            padding: $msv-dialog-modal-content-padding;
        }

        &__header {
            .msc-modal__title {
                @include font-content-l();

                span {
                    &:nth-child(1) {
                        font-weight: var(--msv-font-weight-bold);
                    }
                }
            }
        }

        &__body {
            @include font-content-l();
            text-align: center;

            @include image($msv-dialog-msc-empty_image-size);

            .msc-empty_image {
                padding-bottom: $msv-dialog-modal-content-empty-image-padding-bottom;
            }
        }

        &__image {
            margin-right: auto;
            margin-left: auto;
        }

        &__product-title {
            padding: $msv-dialog-modal-content-body-text-padding;
        }

        &__product-variants {
            @include font-content(var(--msv-font-weight-normal), var(--msv-buybox-text-font-size), $msv-line-height-m);
            padding: $msv-dialog-modal-content-body-text-padding;
        }

        &__product-price {
            font-weight: var(--msv-font-weight-bold);
            padding: $msv-dialog-modal-content-body-text-padding;
        }

        &__go-to-cart {
            @include primary-button(var(--msv-buybox-primary-btn-bg),
            var(--msv-buybox-primary-btn-font-color), var(--msv-buybox-primary-btn-border));
            margin: $msv-dialog-modal-content-button-margin;
            width: $msv-dialog-button-width;
        }

        &__back-to-shopping {
            @include secondary-button();
            margin: $msv-dialog-modal-content-button-margin;
            width: $msv-dialog-button-width;
        }
    }

    @media (max-width: $msv-breakpoint-l) {
        &__media-gallery {
            display: block;
            flex-basis: $media-gallery-width;
            min-width: $media-gallery-width;
            flex-grow: 1;
            flex-wrap: nowrap;
            padding-right: $gutter-width;
        }

        .ms-media-gallery {
            padding-bottom: 54px;

            .msc-carousel {
                &__indicators {
                    bottom: -36px;
                }

                &__control {
                    &__prev, &__next {
                        bottom: -40px;
                        color: var(--msv-buybox-carousel-flipper-color);
                        height: auto;
                        top: auto;
                        width: auto;
                    }

                    &__prev__icon {
                        @include add-icon($msv-ChevronLeft);
                        margin-left: 0;
                    }

                    &__next__icon {
                        @include add-icon($msv-ChevronRight);
                        margin-right: 0;
                    }
                }

                &__control__prev {
                    @include hover-focus {
                        .msc-carousel__control__prev__icon {
                            color: var(--msv-buybox-carousel-flipper-hover-color);
                        }
                    }
                }

                &__control__next {
                    @include hover-focus {
                        .msc-carousel__control__next__icon {
                            color: var(--msv-buybox-carousel-flipper-hover-color);
                        }
                    }
                }
            }
        }
    }

    //mobile vp

    @media (max-width: $msv-breakpoint-m) {
        flex-flow: column;
    }
}
