@import '00-settings/index';
@import '01-tools/index';
@import '02-generic/index';
@import '03-components/index';
@import '04-modules/index';
/**Microsoft Suggestion starts***/

.ms-sign-in__account-item-input,
.ms-sign-up__account-item-input {
  border: 1px solid #d1d1d1 !important;
}
/**Microsoft Suggestion ends***/

.simplefilter {
  margin-bottom: 10px;
  text-align: center;
  font-size: 15px;
  vertical-align: baseline;
  margin: 0; 
}

.simplefilter li {
  background-color: #004ebc;
  color: white;
  cursor: pointer;
  display: inline-block;
  text-transform: lowercase;
  padding: 6px 24px;
  line-height: 24px;
  list-style: none;
  margin: 10px 15px;
}

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0 auto;
}

.imagecover {
  display: block;
  padding: 0px 0px 30px;
  background-color: #fff;
}

.store-location-link,
.store-location-link:hover {
  color: #ffffff;
  position: inherit;
  text-decoration: none;
  background: #004ebc;
  border: 1px solid transparent;
  font-size: 18px;
  line-height: 18px;
  padding: 10px 15px;
  display: block;
  max-width: 240px;
  border-radius: 0px;
  margin: 0 auto;
}

.filtr-container,
.simplefilter {
  width: 100%;
  text-align: center;
  direction: ltr;
}

.filtr-item {
  float: left;
  padding: 1rem !important;
}

.row > .heading {
  color: #004ebc !important;
  padding: 10px 0px !important;
  font-size: 24px !important;
  margin: 15px 0px !important;
  text-transform: uppercase !important;
}

/* Button used to open the chat form - fixed at the bottom of the page */

.open-button {
  background-color: #004ebc;
  color: white;
  padding: 16px 20px;
  border: none;
  cursor: pointer;
  opacity: 1;
  position: fixed;
  top: 230px;
  right: -55px;
  font-size: 18px;
  transform: rotate(-90deg);
  display: block;
  z-index: 9999;
}

/* The popup chat - hidden by default */

.chat-popup {
  display: none;
  position: fixed;
  bottom: 0;
  right: 0px;
  border: 3px solid #f1f1f1;
  z-index: 9999;
  width: 350px;
  height: 500px;
}

.closeChatButton,
.closeChatButton:hover,
.closeChatButton:active {
  position: absolute;
  right: 0px;
  display: block;
  background: none;
  border: 0;
  top: 10px;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
}

.promo-product {
  margin: 10px 0px 0px 0px;
  padding: 0px !important;
}

.promo-product > img {
  min-height: 150px !important;
  width: auto !important;
}
.product-info {
  padding: 0rem 1rem !important;
}

.product-link {
  font-size: 30px !important;
  line-height: 1.1 !important;
  color: #666 !important;
  font-weight: bold !important;
  margin-top: 0px !important;
  text-align: justify !important;
}

.promo-product > .product-info > a:hover {
  text-decoration: none;
}

.feature-product-heading {
  margin: 30px 0px 0px;
  color: #004ebc;
  display: inline-block;
  padding: 12px 10px;
  font-size: 50px;
}
.feature-product-heading:first {
  color: #46a303 !important;
}

.static-banner > .ms-content-block__details {
  display: none !important;
}

.ms-search-result-container__title {
  color: $msv-toysrus-pink !important;
}

.msc-product__title {
  color: $msv-toysrus-blue !important;
  font-weight: 700;

  &:hover {
    text-decoration: none;
  }
}

.msc-product:hover {
  text-decoration: none;
}

.ms-refine-submenu__toggle_expanded.msc-btn,
.ms-refine-submenu__toggle_collapsed.msc-btn,
.slider__labels-item {
  color: $msv-toysrus-blue !important;
}

.ms-search-result-container__Sort-by-category {
  padding: 5px 0px !important;
  position: relative;
}

.ms-search-result-container__category-hierarchy > span {
  color: $msv-toysrus-pink !important;
}

.ms-search-result-container__refiner-section .slider .slider__track,
.ms-search-result-container__refiner-section .slider .slider__thumb {
  background-color: $msv-toysrus-pink !important;
}

.ms-search-result-container__Sort-by-category .msc-l-dropdown__label {
  margin-right: 5px;
  vertical-align: middle;
  padding-left: 8px;
}

.ms-search-result-container__Sort-by-category .msc-l-dropdown {
  border: 1px solid $msv-toysrus-blue;
  color: $msv-toysrus-blue;
  padding: 5px 0px;
}

.ms-search-result-container__Sort-by-category {
  font-weight: 700;
}

.ms-search-result-container__Sort-by-category .msc-l-dropdown__label {
  color: $msv-toysrus-pink !important;
}

.ms-product-search-result__item {
  border: 1px solid #d1d1d1;
  margin: 10px 5px;
}

.ms-product-search-result__item {
  padding: 0px 0px 15px 0px;
}

.msc-product__details {
  padding-left: 10px;
}

.ms-search-result-container__no-results-message > h5,
.msc-choice-summary__item,
.msc-choice-summary__clear-all {
  font-size: 18px;
  color: $msv-toysrus-blue !important;
}

.msc-btn:focus {
  border: 0px dashed #fff !important;
  outline: 0px dashed #000 !important;
}

.ms-search-result-container__title {
  border-bottom: 2px solid #d1d1d1 !important;
}

.msc-l-dropdown__option:hover {
  background-color: #fff !important;
  color: $msv-toysrus-blue !important;
  border: 0px solid #fff !important;
}

.msc-page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: $msv-toysrus-blue;
  background-color: #fff;
  border: 1px solid #dee2e6;
  text-decoration: none;

  &:hover,
  :active {
    text-decoration: none !important;
  }
}

.msc-page-item {
  padding-right: 0px;
}

.msc-page-item.active > .msc-page-link {
  color: #fff;
  background-color: $msv-toysrus-blue;
  text-decoration: none !important;
  border: 1px solid $msv-toysrus-blue;
}

.active {
  text-decoration: none !important;
}

.msc-page-item.disabled .msc-page-link {
  background-color: $msv-toysrus-pink;
  color: #fff;
}

.next.msc-page-item .msc-page-link {
  background-color: $msv-toysrus-blue;
  color: #fff;
}

.ms-product-specification__heading,
.ms-ratings-histogram__heading,
.msc-cart__heading {
  margin-top: 10px;
  color: $msv-toysrus-blue;
  border-bottom: 2px solid $msv-toysrus-blue;
  font-weight: bold;
}

.ms-ratings-histogram__item,
.msc-order-summary__heading,
.ms-checkout__title,
.ms-checkout__guided-card-title {
  color: $msv-toysrus-blue;
  font-weight: bold;
}

.ms-checkout__btn-keep-shopping {
  background-color: $msv-toysrus-pink;
  color: $msv-white;
}

//.msc-address-form__dropdown, ut-modified: removed due to new dropdown with search
.msc-address-form__input {
  border: 1px solid #d1d1d1;
}

.msc-order-summary-wrapper {
  border: 1px solid #d1d1d1;
}

.msc-cart__btn-backtoshopping {
  background-color: #000;
  color: #fff;
  display: block;
}

.msc-cart__btn-guestcheckout {
  background-color: $msv-toysrus-pink;
  color: $msv-white;
  border: 1px solid $msv-toysrus-pink !important;
}

.ms-nav__list__item__link:hover {
  background-color: $msv-toysrus-blue !important;
  color: $msv-white !important;
  text-decoration: none !important;
}

/**border issue fix ***/

.msc-product .msc-empty_image {
  width: auto !important;
}

.msc-modal__header .msc-modal__title,
.msc-modal__header .msc-modal__close-button:before,
.ms-header .msc-btn {
  color: $msv-toysrus-blue;
}

.msc-search-result-modal .ms-sort-and-filter-modal-close.msc-btn {
  background-color: $msv-toysrus-blue !important;
  color: $msv-white !important;
  border: 1px solid $msv-toysrus-blue !important;
}

@media (max-width: 992px) {
  .ms-header {
    padding-top: 0px !important;
  }
}

/**static pages**/

.toy-heading,
.print-heading,
.page-heading {
  text-align: center;
  color: $msv-toysrus-blue !important;
  font-size: 28px !important;
  margin: 25px auto;
}

.static-page-heading {
  color: $msv-toysrus-blue !important;
  font-size: 28px !important;
  margin: 25px auto;
}

.about-us-page-heading {
  color: $msv-toysrus-blue !important;
  font-size: 28px !important;
  margin: 25px 10px 0px 10px;

  line-height: 1;
}

.toy-info {
  padding: 20px 0px;
}

.toy-link {
  font-size: 22px !important;
  padding: 15px 0px 0px !important;
  color: #666;
  font-weight: 700;
  font-size: 1.5em;
  margin-top: 10px;

  &:hover {
    text-decoration: none;
  }
}

.toy-box {
  margin: 15px !important;
  -webkit-box-shadow: 0 6px 6px -6px #777;
  -moz-box-shadow: 0 6px 6px -6px #777;
  box-shadow: 0 6px 6px -6px #777;
  float: left !important;
  clear: both;
}

.toy-box > img {
  max-height: 375px !important;
}

.printContainer {
  width: 80%;
  margin: 0 auto;
}

.printContainer > img,
.printContainer > p,
.printContainer > a {
  margin: 20px 15px;
}

.print-description,
page-description {
  text-align: justify !important;
  font-size: 18px;
  padding: 15px 0px;
  margin: 10px 25px;
}

.about-us-page-description {
  text-align: justify !important;
  font-size: 16px;
  line-height: 24px;
  padding: 0px;
  margin: 10px;
  color: gray;
  font-family: 'BuenosAiresWebBook';
}

.print-download-link {
  display: block;
  color: $msv-white;
  background-color: $msv-toysrus-blue;
  text-decoration: none;
  font-size: 20px;
  max-width: 150px;
  text-align: center;
  padding: 15px 10px;
  margin: 10px 25px;
  border-radius: 5px;

  &:hover {
    text-decoration: none;
  }
}

.table-container {
  padding: 1.5rem;
  margin-right: 0;
  margin-left: 0;
  border-width: 0.2rem;
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  border-collapse: collapse;
}

.table td,
.table th {
  padding: 0.75rem;
  vertical-align: middle;
  border-bottom: 1px solid #dee2e6;
  font-size: 18px;
}

.paddingSide50 {
  padding-left: 50px;
  padding-right: 50px;
}

.p-home-container {
  padding: 25px 50px !important;
  margin: 10px auto !important;
}

.p-home-small-banner {
  padding: 20px !important;
}

.p-home-container > p {
  font-size: 18px;
  text-align: center;
}

.play-table {
  background-color: #e9ecef;
  padding: 25px 50px;
  margin: 20px;
  text-align: center;
}

.play-table > a {
  text-decoration: none;
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 10px;
}

.play-table > p {
  font-size: 18px;
  padding: 20px;
  text-align: center;
}

.play-table > img {
  max-width: calc(100% - (2 * 5px));
}

.doh-iamge > a > img {
  max-width: 310px;
}

.doh-text > p {
  text-align: center;
  color: $msv-toysrus-blue !important;
  font-size: 28px !important;
  margin: 0px auto 25px;
  font-weight: 700;
}

.how-to-img > a > img {
  max-width: 223px;
  max-height: 223px;
}

.center-align {
  text-align: center !important;
}

.p-home-product-bar {
  padding: 0px 50px 0px 50px;
  margin: 0 auto;
}

.how-to {
  color: $msv-toysrus-blue;
  font-size: 16px;
}

.how-to > a {
  color: $msv-gray-500;

  &:hover {
    text-decoration: none;
  }
}

.page-text {
  padding: 0px 50px;
  margin: 20px;
  text-align: center;
}

.page-text > p {
  font-size: 18px;
}

.box {
  width: 80%;
  margin: 30px auto;
  background-color: #e9ecef;
  border-collapse: collapse;
}

.box-1 {
  width: 40%;
  text-align: right;
}

.box-2 {
  text-align: left;
  width: 60%;
  vertical-align: top;
}

.box-2 > p {
  text-align: justify;
  line-height: 25px;
  font-size: 16px;
  padding: 10px 25px;
}

.diy-page-image {
  margin: 0 auto;
  text-align: center;
  width: 75%;
}

.diy-page-text {
  width: 75%;
  margin: 0 auto;
  padding: 20px 50px;
  text-align: center;
}

.diy-page-text > p {
  font-size: 18px;
  line-height: 25px;
}

.diy-page-container {
  width: 60%;
  margin: 0 auto;
}
.static-page-container {
  width: 80%;
  margin: 0 auto;
}

.diy-small-heading {
  text-align: left;
  color: $msv-toysrus-blue;
  font-size: 22px;
  font-weight: normal;
  margin-top: 0px;
  margin-bottom: 50px;
}

.diy-small-heading-padding0 {
  text-align: left;
  color: $msv-toysrus-blue;
  font-size: 18px;
  font-weight: normal;
  padding: 0px !important;
  margin: 20px 0px 0px !important;
}

.diy-youtube-box {
  max-width: 100%;
  height: 407px;
  margin: 0 auto;
}

.step-text {
  padding: 0px 0px 50px;
}

.step-text > p {
  font-size: 18px;
  line-height: 25px;
}

.step-image {
  margin-bottom: 50px;
}
.ms-header__desktop-view {
  margin-top: 10px !important;
}

.ms-search__form-submitSearch {
  color: #fff !important;
}

.ms-checkout-guest-profile__input-text {
  border: 1px solid #ccc !important;
}

.ms-order-confirmation__heading {
  color: $msv-toysrus-blue !important;
}
/**footer****/
footer {
  background-color: #e9ecef !important;
  margin-top: 20px;
}

footer .ms-footer > * > * {
  max-width: 100% !important;
  padding-bottom: 0px !important;
}
footer .ms-footer > * {
  background-color: #e9ecef !important;
  padding-top: 1.25em !important;
  padding-bottom: 1.25em !important;
}
.row {
  margin-right: 0px !important;
  margin-left: 0px !important;
}
.toysrus-footer-row {
  padding-bottom: 10px !important;
}
.footer-collapse,
.card-header {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.unlink {
  border-bottom: 1px solid #ccc;
}
.footertab-label {
  border-top: 1px solid #ccc;
}

#footertab_0 .footertab-label {
  border-top: 0px solid #ccc !important;
}

.hidden-sm-up {
  display: none !important;
}

.card-block {
  padding: 0px !important;
}

.footer-heading,
.footer-headlink {
  font-size: 1rem !important;
  margin-bottom: 0 !important;
  line-height: 2.5em !important;
  color: #666 !important;
}

.follow-us {
  font-size: 1rem !important;
  color: #666 !important;
  padding-bottom: 0px;
  margin-top: 10px;
}

.footer-heading > a,
.footer-headlink > a {
  font-size: 1rem !important;
  color: #666 !important;
}

.footer-heading > a:after {
  content: '\F078';
  font-family: 'Font Awesome 5 Free';
  float: right;
}
.widget-list > li {
  padding: 5px 0px !important;
}

.widget-list > li > a {
  color: #8f8f8f !important;
  font-weight: normal !important;
  font-size: 15px !important;
  font-family: 'BuenosAiresWebBook';
}

.widget-list > li > a:hover,
.footer-heading > a {
  text-decoration: none;
}

.copyright {
  text-align: center;
  margin-top: 1.5rem;
  color: #8f8f8f;
  font-family: 'BuenosAiresWebBook', Lato;
}

.seprator {
  margin-top: 1rem;

  border: 0;
  border-top: 1px solid #ccc;
}
.social-ul > li {
  display: inline-block !important;
  padding-right: 10px !important;
}
.social-icon {
  height: 32px;
}

.footertabs {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.5);
}

.footertab {
  width: 100%;
  overflow: hidden;
}

.footertab-label {
  display: -webkit-box;
  font-size: 1rem;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding: 1em 0em;
  background: #e9ecef;
  font-weight: bold;
  cursor: pointer;
  color: #666 !important;
  /* Icon */
}

.footertab-label:hover {
  background: #e9ecef;
}

.footertab-label::after {
  content: '\276F';
  width: 1em;
  height: 1em;
  text-align: center;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
}

.footertab-content {
  max-height: 0;
  padding: 0 1em;
  color: #2c3e50;

  -webkit-transition: all 0.35s;
  transition: all 0.35s;
}

.footertab-close {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: end;
  justify-content: flex-end;
  padding: 1em;
  font-size: 0.75em;
  background: #e9ecef;
  cursor: pointer;
}

.footertab-close:hover {
  background: #e9ecef;
}
.footerinput {
  position: absolute;
  opacity: 0;
  z-index: -1;
}
.footerinput:checked + .footertab-label {
  background: #e9ecef;
}

.footerinput:checked + .footertab-label::after {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.footerinput:checked ~ .footertab-content {
  max-height: 100vh;
  padding: 0.4em;
}
.legal-logo-container {
  float: right !important;
  text-align: right !important;
}
.legal-logo-link {
  display: table-cell;
  vertical-align: top;
  float: right;
}
.tasweeq-logo {
  height: 65px !important;
  margin-top: 0px;
  padding-right: 10px;
}
.maroof-logo {
  height: 45px !important;
  margin-top: 5px;
}

.full-width {
  width: 100% !important;
}
.lang-link {
  direction: rtl;
  padding-left: 10px;
  &:hover {
    text-decoration: none;
  }
}
/**
.ms-header {
  max-width: 100% !important;
  width: 100% !important;
  padding-top: 15px !important;
}
.ms-header__desktop-view {
  position: absolute;
  right: 25%;
  margin-top: -60px !important;
}



.ms-header__container {
  padding-bottom: 10px !important;
}
.ms-nav > .ms-nav__list > .ms-nav__list__item {
  margin-top: 10px !important;
}
body .container {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
**/

.ms-header__logo > a > picture > img {
  height: 65px !important;
  width: auto !important;
}

.imgBgBanner {
  background-image: url('https://images-eu-prod.cms.commerce.dynamics.com/cms/api/smslnvtclk/imageFileData/MD1XrQ');

  display: flex;
  -ms-flex-align: end;
  align-items: flex-end;
  background-size: cover;
  background-position: 50%;
  min-height: 350px;
}

.imgHelpBanner {
  background-image: url('https://images-eu-prod.cms.commerce.dynamics.com/cms/api/fnqzbfjwxg/imageFileData/MD1qrW');
  display: flex;
  -ms-flex-align: end;
  align-items: flex-end;
  background-size: cover;
  background-position: 50%;
  min-height: 350px;
}

.imgContactBanner {
  background-image: url('https://images-eu-prod.cms.commerce.dynamics.com/cms/api/fnqzbfjwxg/imageFileData/MD1t3k');
  display: flex;
  -ms-flex-align: end;
  align-items: flex-end;
  background-size: cover;
  background-position: 50%;
  min-height: 350px;
}

.imgaboutUsBanner {
  background-image: url('https://images-eu-prod.cms.commerce.dynamics.com/cms/api/fnqzbfjwxg/imageFileData/MD1t3n');
  display: flex;
  -ms-flex-align: end;
  align-items: flex-end;
  background-size: cover;
  background-position: 50%;
  min-height: 350px;
}
.imgPolicyBanner {
  background-image: url('https://images-eu-prod.cms.commerce.dynamics.com/cms/api/fnqzbfjwxg/imageFileData/MD1t3n');
  display: flex;
  -ms-flex-align: end;
  align-items: flex-end;
  background-size: cover;
  background-position: 50%;
  min-height: 350px;
}

.banner-layout-text-small {
  color: $msv-toysrus-blue;
  background-color: #fff;
  transform: translateY(-50%);
  margin: 0;
  padding: 10px 10px 10px 2%;
  font-size: 1.25rem;
  left: 0;
  text-align: center;
  max-width: 40%;
  width: 100%;
  top: 50%;
  position: absolute;
}

.banner-layout-text {
  font-size: 40px;
  background-color: #fff !important;
  color: #666;
  line-height: 1.25;
  margin-bottom: 10px;
  padding: 0.5rem !important;
}
.banner-bg-link {
  display: block;
  padding: 1rem !important;

  &:hover {
    text-decoration: none;
  }
}

.ms-header__divider {
  display: initial !important;
}

.ms-nav__list__item__link {
  font-size: 16px !important;
}
.banner-link {
  display: block;
}
.ms-search__form-control {
  border: 1px solid $msv-toysrus-blue;
}
.ms-search__form-control:focus {
  border: 0px solid #fff;
  outline: 1px solid $msv-toysrus-blue;
}

.paddingLeft20 {
  padding-left: 20px !important;
}

.show-in-mobile {
  display: none;
}
/****
header {
  /**** -webkit-box-shadow: 0 6px 6px -6px #777;
  -moz-box-shadow: 0 6px 6px -6px #777;
  box-shadow: 0 6px 6px -6px #777;
  box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.5);
}
****/
.marginTop25 {
  margin-top: 25px !important;
}
.faq-question,
.policy-heading {
  font-size: 1.5rem !important;
  text-align: left !important;
  margin: 15px 0px !important;
  font-family: 'BuenosAiresWeb';
}

.faq-answer,
.policy-text {
  font-size: 1rem !important;
  text-align: justify !important;
  margin: 10px 0px !important;
  font-family: 'BuenosAiresWebBook';
  color: gray;
  line-height: 24px;
}

.mailchimp-setting {
  position: absolute !important;
  left: -5000px !important;
}
#mc_embed_signup {
  background: #fff;
  clear: left;
  font: 14px Helvetica, Arial, sans-serif;
}
.card-form {
  margin: 2em 1em;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.1875rem;
}
.card-body {
  flex: 1 1 auto;
  padding: 1.25rem;
}
.form-group .form-control-label {
  font-size: 14px;
  margin: 0;
  // position: absolute;
  // top: -3px;
  // left: 0;
  // transform: translate(13px, 13px);
  z-index: 1;
  transition: transform 0.3s ease, font-size 0.3s, color 0.3s;
}
.form-control-label {
  border-radius: 50px;
}
.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.1875rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.form-group {
  position: relative;

  margin-bottom: 1rem;
}
.button-primary-tru-blu-curve,
.button-secondary:hover {
  color: #fff;
  background: #2668c5;
  border: 1px solid #2668c5;
  display: block;
  padding: 10px 30px;
  font-size: 22px;
  width: 100%;
  max-width: 220px;

  cursor: pointer;
}
.first-name-input {
  width: 99%;
}
.comment-text-area {
  height: 120px;
}

// .msc-cart__btn-guestcheckout {
//   display: block !important;
// }

/****mobile settings****/
@media only screen and (max-width: 768px) {
  .ms-search-result-container__title {
    padding-bottom: 10px;
  }
  .ms-product-search-result__item {
    padding: 0px !important;
  }
  .msc-product__details {
    text-align: center;
    padding: 0px !important;
  }
  .ms-product-search-result__item a .msc-price .msc-price__actual {
    display: block !important;
    padding-bottom: 10px !important;
  }

  .ms-search-result-container__Products .list-unstyled {
    display: grid;
    grid-column-gap: 2px;
    grid-template-columns: repeat(auto-fill, minmax(135px, 1fr));
  }
  .msc-product__title {
    font-size: 14px !important;
    margin-bottom: 5px !important;
    margin-top: 5px !important;
    line-height: 14px !important;
  }
  .ms-product-search-result__item a .msc-price .msc-price__actual {
    font-size: 14px;
    line-height: 14px !important;
  }
  .msc-product .msc-empty_image {
    width: 100px !important;
    height: 100px !important;
  }
  .ms-search-result-container {
    padding-left: 0px;
    padding-right: 0px;
  }

  .banner-layout-text-small {
    max-width: 100%;
  }
  .card-form {
    margin: 20px 0px;
  }
  .about-us-page-description {
    text-align: justify !important;
  }
  .legal-logo-link {
    float: left;
  }
  .banner-layout-text {
    font-size: 16px;
  }
  .ms-header__divider {
    margin: 0;
  }
  .ms-nav__drawer {
    border-bottom: 1px solid #ccc;
    background-color: #fff !important;
  }
  .ms-nav__drawer > div > div {
    margin-left: 15px !important;
  }
  .show-in-mobile {
    display: block;
  }
  .open-button,
  .mobile-hiden {
    display: none;
  }
  .seprator {
    margin-top: 0px !important;
  }
  .diy-page-text,
  .diy-page-container,
  .static-page-container,
  .diy-page-image {
    width: 100% !important;
    padding: 10px !important;
  }
  .diy-page-text > p {
    text-align: justify !important;
  }
  .copy-div {
    width: 100% !important;
  }
  .maroof {
    width: 22% !important;
  }
  .tasweeq-logo {
    padding-left: 10px !important;
    padding-right: 0px !important;
    height: 50px;
  }
  .copyright {
    margin-top: 0rem;
    margin-bottom: 10px;
    font-size: 12px !important;
  }
  .maroof-logo {
    float: none !important;
    margin-top: 0px !important;
  }
  .hidden-sm-up {
    display: block !important;
  }
  .hidden-xs-down {
    display: none !important;
  }
  .ms-header__logo > a > picture > img {
    height: auto !important;
    width: 87% !important;
    margin: 0 auto;
  }
  .ms-header .ms-header__collapsible-hamburger {
    margin-top: 10px !important;
  }

  .ms-header__container {
    padding-bottom: 10px !important ;
  }
  .ms-header__logo {
    max-width: 170px !important;
    margin: 0 auto !important;
  }
  .promo-product {
    width: 48% !important;
    clear: both !important;
    margin: 0px !important;
  }
  .product-info {
    width: 48% !important;
    clear: both !important;
    padding: 0px 0px 0px 10px !important;
  }
  .img-link {
    display: block !important;
  }

  .product-row {
    border-bottom: 1px solid #ccc !important;
  }
  .product-info .toy-link {
    display: table-cell;
  }

  .toy-link,
  .product-link {
    padding: 0px;
    font-size: 16px !important;
  }
  .printContainer {
    width: 100% !important;
  }
  .printContainer > img,
  .printContainer > p,
  .printContainer > a {
    margin: 0px !important;
    padding: 10px !important;
  }
  .print-download-link {
    font-size: 16px;
    padding: 10px 0px;
    margin: 10px;
    text-align: center;
  }
  .feature-product-heading1 {
    padding-left: 3%;
    margin-bottom: 1.9%;
    font-size: 25px;
    margin: 30px 0px 0px;
  }
  .copyright {
    text-align: left;
  }
  .feature-product-heading {
    margin: 15px 0px;
    font-size: 35px;
  }
}

.ms-promo-banner {
  padding: 5px !important;
}
.ms-nav > .ms-nav__list > .ms-nav__list__item > .ms-nav__list {
  border: 0px solid #fff !important;
}
/*
.ms-nav > .ms-nav__list > .ms-nav__list__item .ms-nav__list__item__link {
  border-bottom: 1px solid #ccc !important;
  line-height: 35px !important;
  height: 35px !important;
}
.ms-nav > .ms-nav__list > .ms-nav__list__item:last-child {
  border: 0px solid #fff !important;
}
*/

.ms-nav
  > .ms-nav__list
  > .ms-nav__list__item:nth-child(1)
  > .ms-nav__list
  > .ms-nav__list__item:nth-child(3)
  > a {
  background-color: #602c81;
  color: #fff;
}
.ms-nav
  > .ms-nav__list
  > .ms-nav__list__item:nth-child(1)
  > .ms-nav__list
  > .ms-nav__list__item:nth-child(3)
  > a:hover {
  background-color: #9ac4ff !important;
  color: #004ebc !important;
}

.ms-nav > .ms-nav__list > .ms-nav__list__item:nth-child(1) {
  order: 4;
}
.ms-nav > .ms-nav__list > .ms-nav__list__item:nth-child(2) {
  order: 5;
}

.ms-nav > .ms-nav__list > .ms-nav__list__item:nth-child(3) {
  order: 1;
}
.ms-nav > .ms-nav__list > .ms-nav__list__item:nth-child(4) {
  order: 2;
}

.ms-nav > .ms-nav__list > .ms-nav__list__item:nth-child(5) {
  order: 3;
}
.ms-nav > .ms-nav__list > .ms-nav__list__item:nth-child(6) {
  order: 6;
}

.color-e65100 {
  color: #e65100 !important;
}

.color-004ebc {
  color: #004ebc !important;
}

.color-e4005c {
  color: #e4005c !important;
}

.color-46a303 {
  color: #46a303 !important;
}
/**latest changes*/
.ms-header .ms-cart-icon {
  border-left: 0px solid #d1d1d1 !important;
  padding-left: 00px !important;
}
.languageSwitch {
  font-weight: bold;
  text-decoration: none;
}

.vatt-logo {
  width: 45px;
  margin-right: 5px;
}

.msc-price .msc-price__actual,
.msc-price .msc-price__strikethrough{
  font-family: "BuenosAiresWeb" !important;
}