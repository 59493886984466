$name-top-margin: 4px;
$rating-top-margin: 4px;
$price-top-margin: 4px;
$original-price-color: $msv-gray-500;
$original-price-right-margin: 4px;

$product-font: $msv-primary-font-family;

$product-title-color: var(--msv-font-primary-color);
$product-title-line-height: 21px;
$product-title-margin-bottom: 8px;
$product-title-margin-top: 20px;

.msc-product-card {
  font-size: var(--msv-body-font-size-s);

  .msc-product-card__image {
    width: 100%;
  }

  .msc-product-card__name {
    margin-top: $name-top-margin;
  }

  .msc-product-card__price-container {
    margin-top: $price-top-margin;
  }

  .msc-product-card__original-price {
    color: $original-price-color;
    margin-right: $original-price-right-margin;
  }

  .msc-product-card__rating {
    margin-top: $rating-top-margin;
  }
}

.msc-product__title {
  color: $product-title-color;
  font-family: $product-font;
  font-weight: normal;
  font-size: var(--msv-body-font-size-m);
  line-height: $product-title-line-height;
  margin-bottom: $product-title-margin-bottom;
  margin-top: $product-title-margin-top;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: none;
}

.msc-product {
  @include image(290px);
}

.msc-product__availibility {
  display: flex;
  flex-direction: column;
  .msc-product__delivery_shipment {
    color: #545454;
    &::before {
      color: var(--msv-font-primary-color);
      @include msv-icon();
      content: $msv-DeliveryTruck;
      padding-top: 3px;
      padding-left: 3px;
      padding-right: 3px;
    }
  }
  .msc-product__delivery_pickup {
    color: #545454;
    &::before {
      color: var(--msv-font-primary-color);
      @include msv-icon();
      content: $msv-StoreAlt;
      padding-top: 3px;
      padding-left: 3px;
      padding-right: 3px;
    }
  }
  .msc-product__delivery_outofstock {
    color: rgb(214, 37, 37);
  }
}


//start - styles for add to cart button
/*
.ms-product-collection__item .msc-add-to-cart,
.ms-product-search-result__item .msc-add-to-cart,
.redirect-to-details-link {
  display: none;
}

@media screen and (min-width: 769px) {
  .ms-product-collection__item,
  .ms-product-search-result__item {
    position: relative;
    padding-bottom: 30px !important;
  }

  .ms-product-collection__item .msc-add-to-cart,
  .ms-product-search-result__item .msc-add-to-cart {
    visibility: visible;
    position: absolute;
    bottom: 10px;
    right: 20px;
    min-width: 35px;
    min-height: 35px;
    display: inline-block;
  }

  $header-icon-size: 16px;

  button.msc-add-to-cart::after {
    @include msv-icon();
    content: $msv-shopping-bag;
    font-size: $header-icon-size;
    vertical-align: text-bottom;
    text-align: center;
    color: white;
  }

  .redirect-to-details-link {
    display: inline-block;
  }
}

@media screen and (max-width: 769px) {
  .mobile button.msc-add-to-cart::before {
    content: '+ ';
  }
}
*/

.ms-product-collection__item .msc-add-to-cart,
.ms-product-search-result__item .msc-add-to-cart,
.redirect-to-details-link,
span.msc-add-to-cart__text {
  display: none;
}

//styles for desktop view
@media screen and (min-width: 769px) {
  .ms-product-collection__item,
  .ms-product-search-result__item {
    position: relative;
    padding-bottom: 30px !important;
  }

  .ms-product-collection__item .msc-add-to-cart,
  .ms-product-search-result__item .msc-add-to-cart {
    visibility: visible;
    position: absolute;
    bottom: 10px;
    right: 20px;
    min-width: 35px;
    min-height: 35px;
    display: inline-block;
  }

  $header-icon-size: 16px;

  button.msc-add-to-cart::after {
    @include msv-icon();
    content: $msv-shopping-bag;
    font-size: $header-icon-size;
    vertical-align: text-bottom;
    text-align: center;
    color: white;
  }

  .redirect-to-details-link {
    display: inline-block;
  }
}

//styles for mobile view
@media screen and (max-width: 769px) {
  button.msc-add-to-cart::before {
    content: '+ ';
  }
  button.msc-add-to-cart-out-of-stock span.msc-add-to-cart__text {
    display: inline-block;
    font-size: 0.95em;
  }

  button.msc-add-to-cart-out-of-stock::after,
  button.msc-add-to-cart-out-of-stock::before {
    content: '' !important;
  }
}
//end - styles for add to cart button
