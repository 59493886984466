//spacing
$header-container-spacing-desktop: 40px;
$header-container-spacing-mobile-left: 6px;
$header-container-spacing-mobile-right: 12px;
$header-container-spacing-mobile: 0;
$signin-info-spacing-left: 2px;
$navigation-spacing-left: 8px;
$cart-icon-spacing-left: 20px;
$heading-padding-top: 25px;
$signin-info-padding: 12px 14px;
$header-height: 48px;
$header-mobile-height: 48px;
$header-icon-width: 48px;
$account-link-height: 35px;
$account-links-max-width: 276px;
$signin-link-padding: 0 20px;
$signin-link-height: 48px;
$header_container-max-width: $msv-breakpoint-xl;
$header-myaccount-margin-left: 8px;
$header-logo-margin-right: 20px;
$header-logo-margin: auto auto auto 12px;
$header-icon-padding-right: 10px;
$header-icon-padding-left: 10px;
$header-cart-icon-margin-left: 4px;
$header-list-item-link-padding: 0 20px;
$popover-menu-item-height: 48px;
$msv-default-container-padding-bottom: 25px;
$divider-margin-left: 8px;
$divider-margin-right: 8px;
$divider-margin-width: 1px;
$divider-margin-height: 20px;
$divider-padding-m: 10px 0;
$nav-padding-top-m: 20px;
$header-store-selector-btn-padding: 12px 10px;

//style presets
:root {
  --msv-header-bg: var(--msv-bg-color);
  --msv-header-border: var(--msv-border-color);
  --msv-header-font-color: var(--msv-font-primary-color);
  --msv-header-font-size: var(--msv-body-font-size-m);
}

header {
  background-color: var(--msv-header-bg);
}

.ms-header {
  background-color: var(--msv-header-bg);
  color: var(--msv-header-font-color);
  border: 1px solid var(--msv-header-border);

  margin-left: auto;
  margin-right: auto;
  padding-left: $header-container-spacing-mobile;
  padding-right: $header-container-spacing-mobile;
  padding-top: $heading-padding-top;

  &__container {
    padding-bottom: $msv-default-container-padding-bottom;

    .ms-header__logo {
      margin-right: $header-logo-margin-right;

      a {
        @include vfi();
        display: block;

        &:focus {
          outline-offset: -2px;
        }
      }
    }

    .ms-header__collapsible-hamburger {
      .ms-header__divider {
        background: var(--msv-header-bg);
        border-bottom: 1px solid $msv-gray-300;
        padding: $divider-padding-m;
      }

      .ms-nav.mobile-vp {
        //padding-top: $nav-padding-top-m;
      }
    }
  }

  .ms-header__preferred-store-text {
    color: var(--msv-header-font-color);
    font-size: var(--msv-header-font-size);
    margin: 0 8px;
  }

  .ms-header__preferred-store-btn {
    @include add-icon($msv-Shop-Address);
  }

  .ms-header__preferred-store-btn,
  .ms-search__icon,
  .ms-header__signin-button,
  .msc-wishlist-icon,
  .msc-cart-icon,
  .ms-header__profile-button {
    background-color: transparent;
    color: var(--msv-header-font-color);

    @include font-content(
      var(--msv-font-weight-normal),
      var(--msv-header-font-size),
      $msv-line-height-m
    );
    justify-content: center;

    &:focus {
      outline-offset: -1px;
    }
  }

  .ms-search__form-cancelSearch {
    color: var(--msv-header-font-color);

    @include font-content(
      var(--msv-font-weight-normal),
      var(--msv-header-font-size),
      $msv-line-height-m
    );
    justify-content: center;
  }

  .ms-search__form-control,
  .ms-search__form-cancelSearch {
    &:focus {
      outline-offset: -1px;
    }
  }

  // hiding the header icons from login page
  &.hide-header-options {
    .ms-header__nav-icon,
    .ms-header__account-info.account-desktop,
    .ms-header__account-info.account-mobile,
    .msc-cart-icon,
    .ms-header__divider,
    .msc-wishlist-icon {
      display: none;
    }
  }

  .msc-btn {
    height: $header-height;
  }

  .ms-header__topbar {
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    height: $header-mobile-height;
  }

  .ms-header__desktop-view {
    display: none;
  }

  .ms-header__account-info {
    @include font-content(
      var(--msv-font-weight-normal),
      var(--msv-header-font-size),
      $msv-line-height-m
    );
    display: none;
    height: $header-height;
    justify-content: flex-end;
    margin-left: $signin-info-spacing-left;

    &__account-link-container {
      height: $account-link-height;
    }

    /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
    a {
      align-items: center;
      display: flex;
      font-size: $msv-text-size;
      line-height: $msv-text-line-height;
      padding: $signin-link-padding;
      height: $signin-link-height;

      &:hover {
        text-decoration: none;
      }
    }

    .ms-header__signin-button {
      background-color: var(--msv-header-bg);
      color: var(--msv-header-font-color);
      font-weight: var(--msv-font-weight-normal);

      &:focus {
        outline-offset: -1px;
      }
    }

    .msc-popover {
      background-color: var(--msv-header-bg);
      background-clip: padding-box;
      border: 1px solid $msv-gray-500;
      display: block;
      left: 0;
      max-width: $account-links-max-width;
      position: absolute;
      top: 0;
      word-wrap: break-word;
      z-index: 1060;

      .msc-btn {
        color: var(--msv-header-font-color);
        font-weight: var(--msv-font-weight-normal);
        height: $popover-menu-item-height;
      }

      .msc-arrow {
        display: block;
        height: 0.5rem;
        margin: 0 0.3rem;
        position: absolute;
        top: calc((0.5rem + 1px) * -1);
        width: 1rem;

        &::before,
        &::after {
          border-color: transparent;
          border-style: solid;
          border-width: 0 0.5rem 0.5rem 0.5rem;
          content: '';
          display: block;
          position: absolute;
        }

        &::before {
          border-bottom-color: $msv-gray-500;
          top: 0;
        }

        &::after {
          border-bottom-color: var(--msv-border-color);
          top: 1px;
        }
      }
    }
  }

  /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
  .msc-wishlist-icon {
    &:hover {
      text-decoration: none;
    }
  }

  .ms-header__wishlist-desktop {
    align-items: center;
    display: none;
    height: $header-height;
    width: $header-icon-width;

    @include add-icon($msv-Heart, before, 400);

    &::before {
      font-size: var(--msv-header-font-size);
      text-align: center;
      vertical-align: text-bottom;
      width: $header-icon-width;
    }

    .msc-wishlist-icon__text {
      display: none;
    }
  }

  /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
  .ms-header__nav-icon {
    align-items: center;
    background: var(--msv-header-bg);
    display: flex;
    height: $header-height;
    justify-content: center;
    width: $header-icon-width;

    &:focus {
      outline-offset: -1px;
    }

    &::before {
      @include msv-icon();
      content: $msv-GlobalNavButton;
      font-size: var(--msv-header-font-size);
      width: $header-icon-width;
      vertical-align: text-bottom;
      text-align: center;
    }

    &:hover {
      text-decoration: none;
    }
  }

  &.ms-header-open {
    .ms-header__nav-icon {
      &::before {
        content: $msv-Cancel;
      }
    }
  }

  /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
  .msc-cart-icon {
    width: $header-icon-width;
    height: $header-height;
    display: flex;
    align-items: center;

    &::before {
      @include msv-icon();
      content: $msv-shopping-bag;
      font-size: var(--msv-header-font-size);
      width: $header-icon-width;
      vertical-align: text-bottom;
      text-align: center;
    }

    &:hover {
      text-decoration: none;
    }

    &__count {
      @include font-content-s();
      margin-left: $header-cart-icon-margin-left;
    }
  }

  .trus-header__navigate-back_button_text {
    padding: 0 5px;
  }
  
  .trus-header__navigate-back_button {
    //display: flex;
    display: none;
    justify-content: center;
    align-items: center;
    padding: 0;
    height: 48px;
    background: transparent;
    border: none;
    &:before {
      @include msv-icon();
      color: var(--toysrus-nav-carot-color);
      content: $msv-ChevronLeft;
      font-size: var(--msv-header-font-size);
      font-weight: var(--toysrus-font-weight-heavy);
      vertical-align: text-bottom;
      text-align: center;
      display: inline;
    }
  }

  @media (min-width: $msv-breakpoint-xs) {
    .ms-nav.desktop-vp {
      display: none;
    }

    .msc-cart-icon {
      &::before {
        width: $header-icon-width / 2;
      }
    }
  }

  @media (max-width: $msv-breakpoint-l) {
    padding-left: $header-container-spacing-mobile-left;
    padding-right: $header-container-spacing-mobile-right;

    .msc-wishlist-icon {
      @include add-icon($msv-Heart, before, 400);

      &::before {
        padding-right: $header-icon-padding-right;
      }
    }

    &__container {
      .ms-header__topbar {
        justify-content: space-between;

        .ms-header__logo {
          margin: $header-logo-margin;
        }

        .ms-site-picker {
          display: none;
        }
      }

      .ms-header__collapsible-hamburger {
        .ms-nav {
          .ms-nav__drawer {
            .drawer__button {
              @include font-content(
                var(--msv-font-weight-normal),
                var(--msv-header-font-size),
                $msv-line-height-m
              );
              color: var(--msv-header-font-color);
              background: transparent;
              text-align: left;
              width: 100%;

              .ms-nav__drawer-open {
                @include add-icon($msv-ChevronUp, after);
              }

              .ms-nav__drawer-close {
                @include add-icon($msv-ChevronDown, after);
              }

              .drawer__glyph__end {
                position: absolute;
                right: 10px;
              }
            }

            .msc-btn {
              outline-offset: -1px;
            }
          }

          .ms-nav__list__item__link {
            @include font-content(
              var(--msv-font-weight-normal),
              var(--msv-header-font-size),
              $msv-line-height-m
            );
            align-items: center;
            display: flex;
            //padding: $header-list-item-link-padding;
            padding: 16px 20px;
          }
        }
      }
    }

    .ms-header__account-info.account-mobile {
      display: unset;
      margin-left: 0;

      /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
      .drawer__button {
        @include font-content(
          var(--msv-font-weight-normal),
          var(--msv-header-font-size),
          $msv-line-height-m
        );
        @include add-icon($msv-Contact, before, 400);

        &::before {
          padding-right: $header-icon-padding-right;
        }
        background: transparent;
        text-align: left;
        width: 100%;

        .ms-header__drawer-open {
          @include add-icon($msv-ChevronUp, after);
        }

        .ms-header__drawer-close {
          @include add-icon($msv-ChevronDown, after);
        }

        /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
        .drawer__glyph__end {
          position: absolute;
          right: $header-icon-padding-right;
        }
      }

      .ms-header__signin-button {
        display: flex;
        justify-content: flex-start;

        @include add-icon($msv-Contact, before, 400);

        &::before {
          padding-right: $header-icon-padding-right;
        }
      }

      /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
      .msc-btn {
        outline-offset: -1px;
      }
    }

    .ms-header__collapsible-hamburger {
      background: var(--msv-header-bg);
      left: 0;
      margin-top: 2px;
      position: absolute;
      width: 100%;
      z-index: 1012;

      .ms-header__wishlist-mobile {
        text-align: left;
        width: 100%;
      }

      .ms-search {
        justify-content: flex-start;
        display: flex;

        .ms-search__label {
          display: flex;
          width: 100%;

          .ms-search__icon {
            display: flex;
            justify-content: flex-start;
            width: 100%;
          }

          .ms-search__icon-text {
            display: unset;
            padding-left: $header-icon-padding-left;
          }
        }
      }
    }
  }

  @media (min-width: $msv-breakpoint-l) {
    //padding-left: $header-container-spacing-desktop;
    //padding-right: $header-container-spacing-desktop;

    .ms-header__topbar {
    padding-left: $header-container-spacing-desktop;
    padding-right: $header-container-spacing-desktop;
    }

    .ms-header__desktop-view {
      display: block;
    }

    .ms-nav.desktop-vp {
      display: flex;
    }

    &__modal {
      display: none;
    }

    &__profile-button {
      color: var(--msv-header-font-color);
      font-weight: var(--msv-font-weight-normal);
      background: var(--msv-header-bg);

      &::after {
        @include msv-icon();
        content: $msv-ChevronDown;
        font-size: var(--msv-header-font-size);
        margin-left: $header-myaccount-margin-left;
        text-align: center;
        vertical-align: text-bottom;
      }
    }

    &__profile-button[aria-expanded='true'] {
      &::after {
        content: $msv-ChevronUp;
      }
    }

    &__profile-button[aria-expanded='false'] {
      &::after {
        content: $msv-ChevronDown;
      }
    }

    &__divider {
      background: $msv-divider-color;
      height: $divider-margin-height;
      margin-left: $divider-margin-left;
      margin-right: $divider-margin-right;
      width: $divider-margin-width;
    }

    .ms-header__account-info.account-desktop {
      display: block;
    }

    .ms-header__collapsible-hamburger {
      display: none;

      .ms-header__wishlist-mobile {
        text-align: left;
        width: 100%;
      }
    }



    .ms-search {
      display: flex;

      &__icon {
        align-items: center;
        color: var(--msv-header-font-color);
        font-weight: normal;

        &:focus {
          outline-offset: -1px;
        }
      }
    }

    .ms-header__wishlist-desktop {
      display: flex;
    }

    .ms-header__nav-icon {
      display: none;
    }

    .ms-cart-icon {
      padding-left: $cart-icon-spacing-left;
      width: unset;

      &::before {
        width: unset;
        margin-right: 4px;
      }
    }
  }

  @media (max-width: $msv-breakpoint-m) {
    .ms-header {
      &__preferred-store-btn {
        padding: $header-store-selector-btn-padding;
      }

      &__preferred-store-text {
        display: none;
      }
    }

    .trus-header__navigate-back_button {
      display: flex;
    }
  }

  @supports (-webkit-touch-callout: none) {
    .ms-header__preferred-store-btn {
      &:focus {
        border: none;
        outline: 0;
      }
    }
  }

  .trus-accountbutton{
    .ms-header__signin-button-text {
      margin-left: 7px;
    }
  }

  @media (max-width: $msv-breakpoint-l) {

    .ms-site-picker{ order: 1; }
    .ms-header__logo { order: 3; }
    .ms-search{ 
      order: 2; 
      flex-grow: 0;
    }
    .trus-accountbutton{ order: 4; }
    .ms-header__wishlist-desktop{ order: 5; }
    .ms-cart-icon{ order: 6; }

    .ms-header__container {
      .ms-header__topbar {
        .ms-site-picker {
          display: inline-block;
        }
      }
    }

    .ms-search {
      //display: none;
      .ms-search__form {
        top: 100%;
        z-index: 1013;
      }
      .ms-search__autoSuggest {
        top: 100%;
      }
    }
  
    .trus-accountbutton {
      display: flex;
      justify-content: flex-start;
  
      .ms-header__signin-button, .ms-header__profile-button{
        @include add-icon($msv-Contact, before, 400);
      }
  
      &::before {
        padding-right: $header-icon-padding-right;
      }

      .ms-header__signin-button-text{
        display: none;
      }

      .ms-profile-button-text{
        display: none;
      }
    }
  
    .msc-wishlist-icon::before {
      margin-left: -5px;
    }
  
    .ms-header__wishlist-desktop {
        display: block;
  
        &::before {
          width: auto;
        }
    }

    .ms-site-picker__drawer .drawer__button .ms-site-picker__drawer-open::after{
      display: none;
    }

    .ms-search__label.bx-hide{
      display: block;
    }

    .ms-header__collapsible-hamburger{
      min-height: 100vh !important;
    }
  }
}

html[dir='rtl'] {
  .ms-header {    
    .ms-header__collapsible-hamburger {
      .ms-header__wishlist-mobile {
        text-align: right;
      }
    }
    .ms-header__account-info.account-mobile {
      /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
      .drawer__button {
        text-align: right;
      }
    }

    @media (max-width: $msv-breakpoint-l) {
      .msc-wishlist-icon::before {
        margin-right: -250%;
        margin-left: auto;
      }
    }

    .trus-header__navigate-back_button {
      &:before {
        content: $msv-ChevronRight;
      }
    }
  }
}