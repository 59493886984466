$navigation-parent-menu-margin-left: 24px;
$navigation-parent-menu-margin-right: 24px;
$navigation-parent-menu-margin-top: 20px;
$navigation-menu-list-min-width: 250px;
$navigation-sub-menu-padding-left: 20px;
$navigation-sub-menu-padding-right: 20px;
$navigation-menu-active-item-font-weight: 600;
$navigation-menu-item-height: 48px;
$navigation-menu-item-line-height: 45px;
$navigation-menu-button-icon-margin-left: 8px;
$navigation-menu-icon-line-height: 40px;
$navigation-menu-item-underline: 6px;
$navigation-menu-wrapper-height: auto;
$navigation-menu-wrapper-width: 100%;
$navigation-menu-wrapper-left: 0;
$navigation-menu-image-height: 400px;
$navigation-menu-max-image-width: 370px;
$navigation-menu-max-image-height: 330px;
$navigation-menu-min-image-height: 300px;
$navigation-menu-image-width: 50%;
$navigation-menu-image-padding: 40px;
$navigation-menu-image-bottom: 14px;
$navigation-ms-nav-list-width: 100%;
$navigation-ms-nav-submenu-margin-left: 15px;
$navigation-ms-nav-area-width: 63%;
$navigation-ms-category-img-padding: 20px;
$navigation-menu-list-min-width: 180px;
$navigation-ms-category-width: 37%;
$navigation-ms-category-feature-img-title-width: 280px;
$navigation-ms-category-feature-img-title-line-height: 19px;
$navigation-ms-category-feature-img-title-line-padding-top: 5px;
$navigation-menu-placeholder-image-width: 283px;
$navigation-menu-placeholder-image-height: 330px;

$toysrus-container-max-width-xs: 390px;
$toysrus-container-max-width-s: 767px;
$toysrus-container-max-width-m: 991px;
$toysrus-container-max-width-l: 1068px;
$toysrus-container-max-width-tablet: 1210px;
$toysrus-container-max-width-xl: 1476px;
$toysrus-container-max-height: 500px;
$toysrus-icon-arrow: $msv-ChevronLeft;
$toysrus-box-shadow-color: $msv-box-shadow-color;
$toysrus-vlight-grey: #dbdbdb;
//style presets
:root {
    --msv-nav-bg: var(--msv-bg-color);
    --msv-nav-border: transparent;
    --msv-nav-dropdown-border: transparent;
    --msv-nav-carot-color: var(--msv-font-primary-color);
    --msv-nav-font-color: var(--msv-font-primary-color);
    --msv-nav-font-size: var(--msv-body-font-size-m);
    --msv-nav-font-family: #{$msv-primary-font-family};
    --msv-nav-title-font-size: var(--msv-body-font-size-l);

    --toysrus-nav-border-color: #dbdbdb;
    --toysrus-nav-font-size: var(--msv-nav-font-size);
    --toysrus-nav-bg: var(--msv-nav-bg);
    --toysrus-icon-color: var(--msv-nav-font-family);
    --toysrus-nav-font-family: var(--msv-nav-font-family);

    --toysrus-nav-font-color: var(--msv-nav-font-color);
    --toysrus-nav-font-size: var(--msv-nav-font-size);
    --toysrus-nav-carot-color: var(--msv-nav-font-family);

    --toysrus-nav-dropdown-border: var(--msv-nav-dropdown-border);
    --toysrus-icon-font-size-s: 16px;
    --toysrus-font-weight-heavy: 700;
    --toysrus-body-font-size-m: 16px;
    --toysrus-font-secondary-color: white;
    --toysrus-font-weight-normal: 400;
    --toysrus-font-weight-bold: 500;
    --toysrus-font-weight-light: 300;
}

.ms-nav {
    background: var(--msv-nav-bg);
    border: 1px solid var(--msv-nav-border);
    color: var(--msv-nav-font-color);
    width: 100%;
    &.parent {
        border-top: 1px solid var(--toysrus-nav-border-color);
    }

    &.mobile-vp {
        //height: 100vh;
    }
    &.heading {
        padding: 16px 20px;
    }

    .submenu {
        top: 100%;
        left: 0;
        right: 0;
        z-index: 2;
        width: 100%;
        display: none;
        position: absolute;
        background: rgba(255, 255, 255, 0.98);
        padding: 0 calc((100% - 1200px) / 2);
        box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.25);
        min-height: 260px;
        // overflow-y: auto;
        // height: calc(100vh - 345px);
    }
    .extra-menu-items {
        width: 100%;
        height: 100vh;
        //background: rgba(0, 0, 0, 0.5);
        position: fixed;
        left: 0;
        display: none;
    }
    .submenu:hover ~ .extra-menu-items {
        display: block;
    }
    &.sticky-nav {
        .submenu {
            // height: calc(100vh - 345px);
        }
    }

    &__list {
        z-index: 1000;
        &__item {
            font-size: var(--toysrus-nav-font-size);
            font-style: normal;
            white-space: nowrap;

            &__image {
                position: absolute;
                right: 0;
            }
        }
    }

    &__list {
        &.level-2 {
            width: 100%;
        }
    }

    > .ms-nav__list {
        background: var(--toysrus-nav-bg);

        > .ms-nav__list__item {
            background: var(--toysrus-nav-bg);
            &:hover {
                .submenu {
                    display: block;
                    .ms-nav__feature {
                        > ul {
                            overflow-y: auto;
                            overflow-x: hidden;
                            width: 600px;
                            > li {
                                width: 300px;
                                > ul {
                                    left: 300px;
                                    width: auto;
                                    min-width: auto;
                                    > li {
                                        > div {
                                            > ul {
                                                width: auto;
                                                min-width: auto;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            > .ms-nav__list {
                background: var(--toysrus-nav-bg);
                margin-top: -1px;
                min-width: $navigation-menu-list-min-width;
            }
        }
    }

    .nav-menu_back-button_container {
        cursor: pointer;
    } 

}

.ms-nav.mobile-vp {
    > .ms-nav__list__mobile__container {
        border-bottom: 2px solid var(--toysrus-nav-border-color);
        border-top: 1px solid var(--toysrus-nav-border-color);
        background: var(--toysrus-nav-border-color);
        > .nav-menu_back-button_container {
            .ms-nav__list__mobile__container__button {
                background: var(--toysrus-nav-border-color);
            }
            
            span.ms-nav__list__mobile__container__span {
                padding-right: 10px;
            }
        }
    }

    > .ms-nav__list {
        display: flex;
        flex-direction: column;

        > .ms-nav__list__mobile__container {
            span.ms-nav__list__mobile__container__span {
                margin-left: 0;
            }
        }

        > .ms-nav__list__item {
            .ms-nav__list__item__button,
            .ms-nav__list__item__link {
                //margin-left: 15px;
                padding-left: 35px;
            }
        }
    }
}

html[dir='rtl'] {
    .ms-nav.mobile-vp {            
        > .ms-nav__list {
            > .ms-nav__list__item {
                button.ms-nav__list__item__button{
                    text-align: right;

                    > div {
                        padding-right: 10px;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: $msv-breakpoint-xs) {
    .ms-nav {
        &__list {
            display: block;
            flex-wrap: nowrap;
            &__mobile__container {
                padding: 15px 20px;
                display: flex;
                &__button {
                    @include vfi();
                    background-color: var(--toysrus-nav-bg);
                    border: none;
                    color: var(--toysrus-icon-color);
                    font-family: var(--toysrus-nav-font-family);
                    padding-left: 0;
                    padding-right: 0;
                    @include add-icon($msv-ChevronLeft, after);
                    &:after {
                        top: 2px;
                        float: right;
                        position: relative;
                        //@include toysrus-icon();
                        //@include add-icon($msv-ChevronLeft);
                        color: var(--toysrus-nav-carot-color);
                        color: var(--toysrus-icon-color);
                        content: $toysrus-icon-arrow;
                        //transform: rotate(180deg);
                        //-webkit-transform: rotate(180deg);
                        font-size: var(--toysrus-icon-font-size-s);
                        font-weight: var(--toysrus-font-weight-heavy);
                    }

                    &:focus {
                        outline-offset: -1px;
                    }
                }
                &__span {
                    margin-left: 21px;
                    font-weight: 600;
                    color: var(--toysrus-nav-font-color);
                    font-family: var(--toysrus-nav-font-family);
                    font-size: var(--toysrus-nav-font-size);
                }
            }

            &__item {
                border-top: 1px solid var(--toysrus-nav-border-color);
                &__image {
                    display: block;
                }
                &__button {
                    color: var(--toysrus-nav-font-color);
                    padding-left: 0;
                    padding-right: 0;
                    text-align: left;
                    width: 100%;
                    @include add-icon($msv-ChevronLeft, after);
                    &:after {
                        top: 2px;
                        float: right;
                        position: relative;
                        //@include toysrus-icon();
                        //@include add-icon($msv-ChevronLeft);
                        color: var(--toysrus-nav-carot-color);
                        content: $toysrus-icon-arrow;
                        transform: rotate(180deg);
                        -webkit-transform: rotate(180deg);
                        font-size: var(--toysrus-icon-font-size-s);
                        font-weight: var(--toysrus-font-weight-heavy);
                    }
                }

                &__button,
                &__link,
                &__span {
                    padding: 0;
                    background-color: var(--toysrus-nav-bg);
                    border: none;
                    color: var(--toysrus-nav-font-color);
                    display: block;
                    font-family: var(--toysrus-nav-font-family);
                    @include vfi();
                    padding: 16px 20px;
                    font-size: var(--toysrus-body-font-size-m);
                    &:focus {
                        outline-offset: -1px;
                    }

                    div {
                        display: inline-block;
                    }
                }
            }
        }
        > .ms-nav__list {
            outline: none;
            width: 100%;
            .ms-nav__list {
                //height: 100vh;
            }
            > .ms-nav__list__item {
                > .ms-nav__list__item__button {
                    color: var(--toysrus-nav-font-color);
                }
            }
        }
    }

    .ms-nav.child {
        position: absolute;
        /*top: 50px;*/
        background: var(--toysrus-font-secondary-color);
        border-top: 1px solid $toysrus-vlight-grey;
    }
}

@media screen and (min-width: $msv-breakpoint-l) {
    .ms-nav {
        display: none;
        &.parent {
            border: 0;
        }
        &__list {
            //position: absolute;
            &__mobile__container {
                display: none;
            }
        }
        &__list {
            ul {
                position: relative;
                > .ms-nav__list__item {
                    border-top: 1px solid $toysrus-vlight-grey;
                    &:first-child {
                        border: 0;
                    }
                }

                .ms-nav__list {
                    position: relative;

                    .ms-nav__list__item {
                        font-weight: normal;
                        margin-left: 0;
                        .menu-item-image {
                            display: none;
                        }
                    }
                }
            }
            ul li ul {
                top: 0;
                left: 50%;
                right: auto;
                position: absolute !important;
            }

            ul li ul {
                top: 0;
                left: 50%;
                right: auto;
                position: absolute !important;
            }
        }

        > .ms-nav__list {
            display: flex;
            flex-wrap: wrap;
            outline: none;
            position: relative;
            justify-content: center;
            .ms-nav__list {
                height: auto;
                float: left;
                width: 25%;
                padding: 15px 8px;
                min-width: 330px;
                min-height: 260px;
            }
            .menu-item-image {
                float: right;
                padding: 15px 8px;
                img {
                    height: auto;
                    max-height: 240px;
                }
            }
            > .ms-nav__list__item:first-child {
                margin-left: 0;
            }

            > .ms-nav__list__item {
                border: 0;

                > .ms-nav__list__item__button,
                > .ms-nav__list__item__link {
                    @include vfi();
                    // @include font-content(
                    //    var(--msv-font-weight-normal),
                    //     var(--toysrus-nav-title-font-size),
                    //     $toysrus-line-height-l
                    //);
                    align-items: flex-end;
                    background-color: var(--toysrus-nav-bg);
                    border: none;
                    color: var(--toysrus-nav-font-color);
                    display: flex;
                    font-weight: var(--toysrus-font-weight-normal);
                    font-family: var(--toysrus-nav-font-family);
                    justify-content: center;
                    position: relative;
                    padding: 12px 9px;

                    &:hover,
                    &:focus {
                        color: $white;
                        //background: $toysrus-green;
                        background: var(--toysrus-nav-font-color);
                    }

                    &:hover {
                        cursor: pointer;
                    }
                }

                > .ms-nav__list__item__button {
                    &:after {
                        top: 0;
                        @include msv-icon();
                        color: var(--toysrus-nav-carot-color);
                        content: $msv-ChevronDown;
                        font-size: var(--toysrus-nav-font-size);
                        margin-left: $navigation-menu-button-icon-margin-left;
                        display: none;
                    }
                }

                .ms-nav__list__item__link,
                .ms-nav__list__item__span {
                    height: auto;
                    line-height: 20px;
                    color: var(--toysrus-nav-font-color);
                    padding: 10px 25px 10px 10px;
                    justify-content: flex-start;
                    width: 100%;
                    background: none;
                    position: relative;
                    &:hover {
                        background: $toysrus-vlight-grey;
                        text-decoration: none;
                    }
                }
                .ms-expand-arrow {
                    @include add-icon($msv-ChevronLeft, after);
                    &:after {
                        //@include toysrus-icon();
                        //@include add-icon($msv-ChevronLeft);
                        content: $toysrus-icon-arrow;
                        font-size: var(--toysrus-icon-font-size-s);
                        transform: rotate(180deg);
                        color: var(--toysrus-icon-color);
                        position: absolute;
                        top: 12px;
                        right: 5px;
                    }
                }

                > .ms-nav__list__item__link {
                    display: flex;
                    padding-left: 0;
                    padding-right: 0;
                    justify-content: center;
                    line-height: $navigation-menu-item-line-height;

                    &:hover,
                    &:focus {
                        background: transparent;
                        text-decoration: none;
                        &:before {
                            width: 100%;
                        }
                    }
                }

                > .ms-nav__list__item__button:first-child {
                    margin-left: 0;
                }
                > .ms-nav__list {
                    border: 1px solid var(--toysrus-nav-dropdown-border);
                    box-shadow: 0px 8px 15px $toysrus-box-shadow-color;
                }
            }
        }
    }
}

@media screen and (min-width: $msv-breakpoint-l) {
    
    html[dir='rtl'] {
        .ms-nav {
            &__list {
                ul li ul {
                    left: auto;
                }
            }

                
            > .ms-nav__list {
                .ms-nav__list {
                    float: none;
                }

                > .ms-nav__list__item {
                    .submenu {
                        .ms-nav__feature{
                            > ul.ms-nav__list{
                                display: inline-block;
                            }
                            div.menu-item-image{
                                float: left;
                            }
                        }
                    }
                }
            }
        }

        
    }
}


@media screen and (min-width: $toysrus-container-max-width-m) and (max-width: $toysrus-container-max-width-tablet) {
    .ms-nav {
        > .ms-nav__list {
            .menu-item-image {
                width: calc(50% - 170px);
            }
        }
    }
}
@media screen and (min-width: $toysrus-container-max-width-tablet) {
    .ms-nav {
        > .ms-nav__list {
            .menu-item-image {
                width: calc(50% - 60px);
            }
        }
    }
}
@media screen and (min-width: $msv-container-max-width-m) and (max-width: $toysrus-container-max-width-tablet) {
    .ms-header .ms-nav.mobile-vp {
        display: block;
        height: auto;
    }
    .ms-nav > .ms-nav__list > .ms-nav__list__item > .ms-nav__list__item__link {
        display: flex;
        padding-left: 0;
        padding-right: 0;
        justify-content: center;
        line-height: 24px;
        padding: 12px;
    }

    .nav-menu-category
    {
        white-space: break-spaces;
        max-width: 140px;
        vertical-align: top;

        > .ms-nav__list__item__button
        {
            height: 100%;
            text-align: center;
            align-items: flex-start !important;
        }
    }
}