.gift-finder-ageinput-container {
    display: block;
    border-radius: 4px;
    float: left;
    padding: 10px;
    margin: 9px;
    width: 170px;
}

.gift-finder-divcontainer1 {
    background: #ea175a;
}

.gift-finder-divcontainer2 {
    background: #1684df;
}

.gift-finder-divcontainer3 {
    background: #7bd843;
}

.gift-finder-divcontainer4 {
    background: #20cfae;
}


.gift-finder-divcontainer5 {
    background: #976ce5;
}


.gift-finder-divcontainer6 {
    background: #f64c9d;
}


/* The container */
.gift-finder-container {
    padding-right: 13px;
    color: #ffffff;
    display: blockx;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 700;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.gift-finder-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.gift-finder-checkmark {
    position: absolute;
    border-radius: 20px;
    top: -3px;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
}

/* On mouse-over, add a grey background color */
.gift-finder-container:hover input ~ .gift-finder-checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.gift-finder-container input:checked ~ .gift-finder-checkmark {
    background-color: #0060b1;
}

/* Create the checkmark/indicator (hidden when not checked) */
.gift-finder-checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.gift-finder-container input:checked ~ .gift-finder-checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.gift-finder-container .gift-finder-checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.clr-bth {
    clear: both;
}

.giftfinder-lable {
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 2rem;
    position: relative;
    text-align: center;
}

.Giftfinder-agecontainer {
    text-align: center;
    width: 600px;
    margin: 0 auto;
    max-width: 100%;
}


.gift-finder-body {
    input[type="range"] {
        -webkit-appearance: none;
        margin: 10px 0;
        //width: 100%;
    }

    input[type="range"]:focus {
        outline: none;
    }

    input[type="range"]::-webkit-slider-runnable-track {
        width: 100%;
        height: 5px;
        cursor: pointer;
        animate: 0.2s;
        box-shadow: 0px 0px 0px #000000;
        //background: #bed731;
        border-radius: 1px;
        border: 0px solid #000000;
    }

    input[type="range"]::-webkit-slider-thumb {
        box-shadow: 0px 0px 1px #000000;
        border: 0px solid #2aace2;
        height: 28px;
        width: 28px;
        border-radius: 6px;
        background: #2aace2;
        cursor: pointer;
        -webkit-appearance: none;
        margin-top: -11.5px;
    }

    input[type="range"]:focus::-webkit-slider-runnable-track {
        //background: #bed731;
    }

    input[type="range"]::-moz-range-track {
        width: 100%;
        height: 5px;
        cursor: pointer;
        animate: 0.2s;
        box-shadow: 0px 0px 0px #000000;
        //background: #bed731;
        border-radius: 1px;
        border: 0px solid #000000;
    }

    input[type="range"]::-moz-range-thumb {
        box-shadow: 0px 0px 1px #000000;
        border: 0px solid #2aace2;
        height: 28px;
        width: 28px;
        border-radius: 6px;
        background: #2aace2;
        cursor: pointer;
    }

    input[type="range"]::-ms-track {
        //width: 100%;
        height: 5px;
        cursor: pointer;
        animate: 0.2s;
        background: transparent;
        border-color: transparent;
        color: transparent;
    }

    input[type="range"]::-ms-fill-lower {
        //background: #bed731;
        border: 0px solid #000000;
        border-radius: 2px;
        box-shadow: 0px 0px 0px #000000;
    }

    input[type="range"]::-ms-fill-upper {
        //background: #bed731;
        border: 0px solid #000000;
        border-radius: 2px;
        box-shadow: 0px 0px 0px #000000;
    }

    input[type="range"]::-ms-thumb {
        margin-top: 1px;
        box-shadow: 0px 0px 1px #000000;
        border: 0px solid #2aace2;
        height: 28px;
        width: 28px;
        border-radius: 6px;
        background: #2aace2;
        cursor: pointer;
    }

    input[type="range"]:focus::-ms-fill-lower {
        //background: #bed731;
    }

    input[type="range"]:focus::-ms-fill-upper {
        //background: #bed731;
    }
}

.gift-finder-budgetcontainer {
    margin: 0 auto;
    padding-top: 1.5rem;
    width: 18.875rem;
    /* display:none;*/
}

.giftfinder-currency {
    font-weight: 700;
    margin-top: 1.25rem;
    text-align: center;
}

.giftfinder-header-text {
    font-size: 1.125rem;
    line-height: 1.5rem;
    background: #f0b043;
    font-weight: 600;
    color: #ffffff;
    padding: 5px;
    margin: 0 auto;
    text-align: center;
    border-top: 2px solid #f0b043;
}
.giftfinder-header {
    // background-color: #d7e8fa;
    /* background-image: url(https://images-eu-prod.cms.commerce.dynamics.com/cms/api/smslnvtclk/imageFileData/ME5sbR?pubver=0); */
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: auto 49.125rem;
    min-height: 6.25rem;
    padding: 1rem;
    position: relative;
    text-align: center;
}

.giftfinder-header:after {
    background-image: url(https://images-eu-prod.cms.commerce.dynamics.com/cms/api/smslnvtclk/imageFileData/ME5sbU?pubver=0);
    background-position: 50% 100%;
    background-size: 100% 100%;
    /* bottom: -1%; */
    content: "";
    height: 200px;
    width: 200px;
    bottom: -37px;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
}

.gift-finder-body {
    background: #d7e8fa;
    border-bottom: 2px solid #f0b043;
    padding-bottom: 20px;
    background-image: url(https://images-eu-prod.cms.commerce.dynamics.com/cms/api/smslnvtclk/imageFileData/MD78fn?pubver=0);   
}

.giftfinder-button {
    width: 200px;
    margin: 0 auto;
    color: #fff;
    background-color: #2aace2;
    border-color: #2aace2;
    display: -moz-box;
    display: flex;
    -moz-box-align: center;
    align-items: center;
    -moz-box-pack: center;
    justify-content: center;
    font-weight: 700;

    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 0;
    padding: 0.5rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    -webkit-transition-property: all;
    transition-property: all;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
    cursor: pointer;
}

.gift-finder-green-button {
    background: #bed731;
}








.eidbox-header {
     background-color: #d7e8fa;
    /* background-image: url(https://images-eu-prod.cms.commerce.dynamics.com/cms/api/smslnvtclk/imageFileData/ME5sbR?pubver=0); */
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: auto 49.125rem;
    min-height: 6.25rem;
    padding: 1rem;
    position: relative;
    text-align: center;
}

.eidbox-header:after {
    background-image: url(https://images-eu-prod.cms.commerce.dynamics.com/cms/api/smslnvtclk/imageFileData/ME9rkr?pubver=0);
    background-position: 50% 100%;
    background-size: 100% 100%;
    /* bottom: -1%; */
    content: "";
    height: 200px;
    width: 200px;
    bottom: -46px;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
}
.eidbox-header-text {
    font-size: 1.125rem;
    line-height: 1.5rem;
    background: #1861ae;
    font-weight: 600;
    color: #ffffff;
    padding: 5px;
    margin: 0 auto;
    text-align: center;
    border-top: 2px solid #1861ae;
}
.eidbox-body {
    background: #d7e8fa;
    border-bottom: 5px solid #1861ae;
    border-right: 5px solid #1861ae;
    border-left: 5px solid #1861ae;
    padding-bottom: 20px;
    background-image: url(https://images-eu-prod.cms.commerce.dynamics.com/cms/api/smslnvtclk/imageFileData/ME5sbR?pubver=0);
}


.eidbox-agecontainer {
    text-align: center;
    width: 380px;
    margin: 0 auto;
    max-width: 100%;
}


.eidbox-budgetcontainer {
    margin: 0 auto;
    padding-top: 1.5rem;
   
    text-align: center;
    width: 380px;
    margin: 0 auto;
    max-width: 100%;
    /* display:none;*/
}


.giftfinder-back1{
    margin-top: 20px;
    background-color: #616365;
}


.toysrus-giftfinder-range-container {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    
    .slider {
        position: relative;
        width: 200px;
    }
    
    .slider__track,
    .slider__range,
    .slider__left-value,
    .slider__right-value {
        position: absolute;
    }
    
    .slider__track,
    .slider__range {
        border-radius: 3px;
        height: 5px;
    }
    
    .slider__track {
        background-color: #ced4da;
        width: 100%;
        z-index: 1;
    }
    
    .slider__range {
        background-color: #bed731;
        z-index: 2;
    }
    
    .slider__left-value,
    .slider__right-value {
        //color: #dee2e6;
        color: #b3cd22;
        font-size: 12px;
        margin-top: 20px;
    }
    
    .slider__left-value {
        left: 6px;
    }
    
    .slider__right-value {
        right: -4px;
    }
    
    /* Removing the default appearance */
    .thumb,
    .thumb::-webkit-slider-thumb {
        -webkit-appearance: none;
        -webkit-tap-highlight-color: transparent;
    }
    
    .thumb {
        pointer-events: none;
        position: absolute;
        height: 0;
        width: 200px;
        outline: none;
    }
    
    .thumb--left {
        z-index: 3;
    }
    
    .thumb--right {
        z-index: 4;
    }
    
    /* For Chrome browsers */
    .thumb::-webkit-slider-thumb {
        background-color: #f1f5f7;
        border: none;
        border-radius: 50%;
        box-shadow: 0 0 1px 1px #ced4da;
        cursor: pointer;
        height: 18px;
        width: 18px;
        margin-top: 4px;
        pointer-events: all;
        position: relative;
    }
    
    /* For Firefox browsers */
    .thumb::-moz-range-thumb {
        background-color: #f1f5f7;
        border: none;
        border-radius: 50%;
        box-shadow: 0 0 1px 1px #ced4da;
        cursor: pointer;
        height: 18px;
        width: 18px;
        margin-top: 4px;
        pointer-events: all;
        position: relative;
    }      
}

.min-max-slider {
    position: relative;
    width: 200px;
    text-align: center;
    margin-bottom: 50px;

    input[type="range"] {
        --minRangePercent: 0%;
        --maxRangePercent: 0%;
        height: .4rem;
    }
    input[type="range"]:invalid {
        box-shadow: none;
    }
    
    input[type="range"]::-webkit-slider-thumb {
    
    }
    
    &::focus {
        oultine: none;
    }
    
    input::focus {
        outline: none;
    }
    
    > label {
        display: none;
    }
    
    > input {
        cursor: pointer;
        position: absolute;
    }
    
    /* webkit specific styling */
    > input {
        -webkit-appearance: none;
        -moz-appearance: none;
        outline: none !important;
        background: transparent;
    }
    > input.min {
        background-image: linear-gradient(
            to right,
            silver 0%,
            silver var(--minRangePercent),
            orange var(--minRangePercent),
            orange 100%
        );
    }
    
    > input.max {
        background-image: linear-gradient(
            to right,
            orange 0%,
            orange var(--maxRangePercent),
            silver var(--maxRangePercent),
            silver 100%
        );
    }
    
    > input::-webkit-slider-runnable-track,
    > input::-moz-range-track,
    > input::-ms-track {
        box-sizing: border-box;
        border: none;
        height: 4px;
        background: orange;
        border-radius: 8px;
        height: 10px;
        background-color: transparent;
        background-image: linear-gradient(orange, orange),
            linear-gradient(orange, orange);
        background-size: var(--sx) 10px, calc(100% - var(--sx)) 4px;
        background-position: left center, right center;
        background-repeat: no-repeat;
    }
    
    > input::focus {
        outline: none;
    }
    
    > input.max::-moz-range-progress {
        background: orange;
        border-radius: 4px;
    }
    
    > input.min::-moz-range-progress {
        height: 0.6em;
        background: silver;
        border-radius: 4px;
    }
    
    input[type="range"]::-webkit-slider-thumb,
    input[type="range"]::-moz-range-thumb {
        -webkit-appearance: none; /* Override default look */
        -moz-appearance: none;
        appearance: none;
        width: 20px; /* Set a specific slider handle width */
        height: 20px; /* Slider handle height */
        background: orange; /* Green background */
        cursor: pointer; /* Cursor on hover */
        border: none;
        color: 1px solid orange;
        border-radius: 50%;
        /* box-shadow: -205px 0 0 200px hsl(100, 100%, 40%); */
    }
    
    > input::-webkit-slider-runnable-track {
        cursor: pointer;
    }
}
