// Form elements

@mixin error-message() {
  @include font-content-m($font-weight-bold);
  display: block;
  color: var(--msv-error-color);
}

@mixin form-control() {
  display: block;
  width: 100%;
  color: $msv-gray-tint;
  background-color: #ffffff;
  border: 1px solid #6b727a;
  border-radius: 0;

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
    Arial, sans-serif;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  text-transform: none;
  height: 48px;
  padding: 4px 8px;
  background-clip: border-box;
  border-color: transparent;
  position: relative;
  outline-offset: 1px;
}

@mixin form-input-label() {
  @include font-content-l();
  display: block;
  margin-bottom: 4px;
}

@mixin form-input-checkbox() {
  height: 18px;
  width: 18px;
}

@mixin form-input-radio {
  @include vfi();
  vertical-align: middle;
  height: 24px;
  width: 24px;
  padding: 0;
  margin: 0;
}

@mixin form-input-radio-label {
  @include font-content-m(var(--msv-font-weight-light));
  vertical-align: middle;
  margin-left: 20px;
}

@mixin form-input-el(
  $bg-color: var(--msv-bg-color),
  $color: var(--msv-font-primary-color),
  $border: transparent
) {
  @include vfi();
  @include font-content-l();
  height: 48px;
  padding: 4px 8px;
  background-color: $bg-color;
  color: $color;
  border-color: $border;
}

@mixin form-input-el-error() {
  border: 1px dashed var(--msv-error-color);
  border-left: 3px solid var(--msv-error-color);
}

@mixin form-input-error-message() {
  @include error-message();
  @include add-icon($msv-IncidentTriangle);
  margin-bottom: 4px;

  &::before {
    margin-right: 8px;
  }
}

@mixin form-input-alert-message() {
  @include font-content-s-height-l();
  color: $msv-text-color;
  margin-bottom: 4px;
}

@mixin alert {
  background-color: $pink;
  border: 1px solid $red;
  max-width: 512px;
  padding: 8px;
}

@mixin alert-title {
  @include error-message();
  margin-bottom: 10px;
  margin-top: 2px;

  &::before {
    @include font-awesome-icon($alert-icon);
    margin-right: 8px;
  }
}
