$msv-icon-font-family: 'Font Awesome 5 Free';
$msv-solid-icon-weight: 900;
$msv-outline-icon-weight: 400;

$msv-font-path: '../../../webfonts';

$msv-CommentSolid: '\f27a';
$msv-ChevronDown: '\f078';
$msv-ChevronUp: '\f077';
$msv-Edit: '\f303';
$msv-Add: '\f067';
$msv-Cancel: '\f00d';
$msv-More: '\f141';
$msv-Settings: '\f013';
$msv-Phone: '\f095';
$msv-Shop: '\f290';
$msv-Shop-Address: '\f3c5';
$msv-Filter: '\f0b0';
$msv-Search: '\f002';
$msv-FavoriteStar: '\f005';
$msv-FavoriteStarFill: '\f005';
$msv-half-star: '\f089';
$msv-Delete: '\f2ed';
$msv-Save: '\f0c7';
$msv-Play: '\f04b';
$msv-Pause: '\f04c';
$msv-ChevronLeft: '\f053';
$msv-ChevronRight: '\f054';
$msv-Contact: '\f007';
$msv-Warning: '\f071';
$msv-IncidentTriangle: '\f071';
$msv-Dislike: '\f165';
$msv-Like: '\f164';
$msv-DislikeSolid: '\f165';
$msv-LikeSolid: '\f164';
$msv-Comment: '\f27a';
$msv-Heart: '\f004';
$msv-HeartFill: '\f004';
$msv-IdCard: '\f2c2';
$msv-DeliveryTruck: '\f0d1';
$msv-SolidPlay: '\f04b';
$msv-Mute: '\f6a9';
$msv-UnMute: '\f028';
$msv-Fullscreen: '\f065';
$msv-ExitFullscreen: '\f066';
$msv-ClosedCaptions: '\f20a';
$msv-Selected: '\f00c';
$msv-Error: '\f06a';
$msv-Checkbox: '\f0c8';
$msv-Checkbox-Checked: '\f14a';
$msv-Checkbox-Circle-Checked: '\f058';
$msv-RadioBtnOff: '\f111';
$msv-RadioBtnOn: '\f192';
$msv-SignIn: '\f2f6';
$msv-SignOut: '\f2f5';
$msv-History: '\f1da';
$msv-MapPin: '\f276';
$msv-ContactCard: '\f2c2';
$msv-GlobalNavButton: '\f0c9';
$msv-Remove: '\f068';
$msv-ShoppingCart: '\f07a';
$msv-ToggleLeft: '\f204';
$msv-ToggleRight: '\f205';
$msv-arrow-right: '\f061';
$msv-Spinner: '\f110';
$msv-file: '\f15c';
$msv-StoreAlt: '\f54f';
$msv-DollarSign: '\f155';
$msv-OrganizationUsers: '\f0c0';
$msv-ListAlt: '\f022';
$msv-List: '\f0ca';
$msv-TH-List: '\f00b';
$msv-TH-Large: '\f009';
$msv-Arrow-Right-In-Circle: '\f35a';
$msv-Ellipses-V: '\f142';
$msv-Arrow-Down: '\f063';
$msv-Arrow-Up: '\f062';
$msv-PlusSquare: '\f0fe';
$msv-InfoCircle: '\f05a';
$msv-Plus: '\f067';
$msv-BackArrow: '\f060';
$msv-invoice: '\f570';
$msv-cube: '\f1b2';
$msv-success: '\f058';
$msv-warning: '\f05a';
$msv-error: '\f057';
$msv-envelope: '\f658';

$msv-magnifying-glass: $msv-Search;
$msv-heart: $msv-Heart;
$msv-shopping-bag: $msv-Shop;
$msv-x-shape: $msv-Cancel;

@font-face {
  font-family: $msv-icon-font-family;
  font-style: normal;
  font-weight: $msv-outline-icon-weight;
  font-display: auto;
  src: url('#{$msv-font-path}/fa-regular-400.woff2') format('woff2'),
    url('#{$msv-font-path}/fa-regular-400.woff') format('woff');
}

@font-face {
  font-family: $msv-icon-font-family;
  font-style: normal;
  font-weight: $msv-solid-icon-weight;
  font-display: auto;
  src: url('#{$msv-font-path}/fa-solid-900.woff2') format('woff2'),
    url('#{$msv-font-path}/fa-solid-900.woff') format('woff');
}

@mixin msv-icon($icon-weight: $msv-solid-icon-weight) {
  font-family: $msv-icon-font-family;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  font-weight: $icon-weight;
  text-rendering: auto;
  line-height: 1;
}

@mixin add-icon(
  $icon,
  $selector: before,
  $icon-weight: $msv-solid-icon-weight
) {
  &:#{$selector} {
    @include msv-icon($icon-weight);
    content: $icon;
  }
}

@mixin add-spinner($selector: before) {
  @include add-icon($msv-Spinner, $selector);
  &:#{$selector} {
    margin-right: 8px;
    -webkit-animation: spin 1s steps(8) infinite;
    animation: spin 1s steps(8) infinite;
  }
  cursor: progress;
}
