$msv-checkout-button-margin: 15px auto;
$msv-checkout-button-text-align: center;
$msv-checkout-button-align-items: center;
$msv-checkout-button-justify-content: center;
$msv-checkout-button-width: 100%;
$msv-checkout-button-border-radius: 2px;
$divider-vertical-margin: 20px;
$cart-items-right-margin: 40px;
$cart-empty-image-width: 183px;
$cart-empty-image-width-m: 80px;
$summary-min-width: 320px;
$summary-max-width: 480px;
$cart-heading-bottom: 20px;
$cart-heading-top: 32px;
$cart-cartline-wrapper-grp-padding: 32px;
$cart-cartline-wrapper-margin-right: 40px;
$cart-cartline-wrapper-margin-bottom: 1.5rem;
$cart-cartline-line-padding-right: 20px;
$cart-content-margin-left: 200px;
$cart-cartline-line-product-padding-bottom: 10px;
$cart-cartline-line-product-price-padding-top: 10px;
$cart-cartline-line-product-price-margin-left: 0;
$cart-cartline-item-content-margin-left-m: 100px;
$cart-promo-code-input-width-m: 170px;
$cart-order-summary-padding-m: 20px;
$cart-promo-code-input-height: 50px;
$msv-promotion-icon-right-margin: 8px;
$cart-promotion-padding: 20px;
$cart-promotion-margin-bottom: 5px;
$cart-promotion-popup-close-button-padding: 5px;
$cart-lines-group-heading-padding-top: 12px;
$cart-lines-group-heading-padding-left: 76px;
$cart-line-group-extra-action-margin-top: 75px;
$cart-line-group-extra-action-margin-left: 200px;

//style presets
:root {
    // heading
    --msv-cart-heading-font-size: var(--msv-heading-font-size-s);
    --msv-cart-heading-font-color: var(--msv-font-primary-color);
}

.msc-cart__btn-checkout,
.msc-cart__btn-guestcheckout,
.msc-promo-code__apply-btn {
    @include button-default();
    @include primary-button(var(--msv-cart-primary-btn-bg),
    var(--msv-cart-primary-btn-font-color), var(--msv-cart-primary-btn-border));
    align-items: $msv-checkout-button-align-items;
    display: flex;
    justify-content: $msv-checkout-button-justify-content;
}

.msc-cart__btn-checkout,
.msc-cart__btn-guestcheckout,
.msc-cart__btn-backtoshopping {
    margin: $msv-checkout-button-margin;
    width: $msv-checkout-button-width;
}

.msc-cart__btn-guestcheckout {
    @include button-default();
    @include secondary-button(var(--msv-cart-secondary-btn-bg),
    var(--msv-cart-secondary-btn-font-color), var(--msv-cart-secondary-btn-border));
}

.msc-cart__btn-backtoshopping {
    @include button-link(var(--msv-cart-btn-link-color));
}

.ms-cart {
    display: flex;
    flex-wrap: wrap;

    .msc-cart__heading {
        @include font-content(var(--msv-font-weight-normal),
        var(--msv-cart-heading-font-size), $msv-title-line-height-xs);
        color: var(--msv-cart-heading-font-color);
        flex: 0 0 100%;
        margin-bottom: $cart-heading-bottom;
        margin-top: $cart-heading-top;
    }

    .msc-cart__promotion {

        width: 100%;
        background-color: $msv-white;
        padding: $cart-promotion-padding;
        margin-bottom: $cart-promotion-margin-bottom;

        &_link {
            margin-left: 8px;
            text-decoration: underline;
            cursor: pointer;
        }

        .msi-promotion-icon {
            margin-right: $msv-promotion-icon-right-margin;

            @include add-icon($msv-DollarSign, before);
            display: none;
        }

        &-popup {
            position: absolute;
            width: auto;
            height: auto;
            z-index: 2;
            background-color: $msv-white;
            left: 0;

            &_content {
                color: var(--msv-font-primary-color);
                background-clip: padding-box;
                background-color: var(--msv-bg-color);
                border: 1px solid $msv-gray-400;
                outline: 0;
                pointer-events: auto;
                position: relative;
                width: 400px;
                min-width: 335px;
                padding: 20px 10px;

                .msc-cart__promotion-popup_title {
                    @include font-content-xl();
                    margin: 0;
                    text-transform: none;
                }

                .msc-cart__promotion-popup_close-button {
                    @include add-icon($msv-x-shape);
                    @include vfi();
                    background-color: transparent;
                    border: 0;
                    color: var(--msv-font-primary-color);
                    font-size: 20px;
                    padding: $cart-promotion-popup-close-button-padding;
                    position: absolute;
                    right: 15px;
                    text-shadow: 0 1px 0 var(--msv-bg-color);
                    top: 22px;
                }

                ul {
                    list-style: none;
                    padding: 0;

                    .msc-cart__promotion-popup_name {
                        display: block;
                    }
                }
            }
        }
    }

    .msc-cart__btn-addcarttotemplate.disabled {
        background-color: $msv-white;
        color: $msv-gray-300;
        text-decoration: underline;
    }

    .msc-cart__btn-addcarttotemplate-tooltip {
        opacity: 1;

        .msc-tooltip-inner {
            background-color: $msv-white;
            box-shadow: 0 8px 16px rgba(0, 0, 0, 0.12), 0 0 1px rgba(0, 0, 0, 0.14);
            color: $msv-black;
            font-size: 14px;
            line-height: 16px;
            padding: 12px;
            text-align: left;
        }

        .msc-arrow::before {
            border-top-color: $msv-white;
        }
    }

    @media screen and (max-width: $msv-breakpoint-m) {
        .msc-cart__promotion {
            &-popup {
                &_content {
                    width: 335px;
                }
            }
        }
    }

    .msc-cartline-wraper {
        border-top: 1px solid var(--msv-cart-border);
        flex: 1;
        margin-bottom: $cart-cartline-wrapper-margin-bottom;
        margin-right: $cart-cartline-wrapper-margin-right;
    }

    .msc-cart-line {
        position: relative;

        &__product-image {
            position: absolute;

            @include image($cart-empty-image-width);
        }

        &__invoice-image {
            position: absolute;

            @include invoice-image($cart-empty-image-width);
        }

        &__content,
        &__bopis-container,
        &__extra-actions,
        &__eid {
            margin-left: $cart-content-margin-left;
        }

        &__giftwrapping {
            padding-left: $cart-content-margin-left;
        }
    }

    .msc-cart-lines-item > .msc-cart-line__add-to-wishlist ~ .msc-cart-line__remove-item {
        margin-left: auto;
    }

    /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
    .msc-cart-lines-item > .msc-cart-line__remove-item,
    .msc-cart-lines-item > .msc-cart-line__add-to-wishlist {
        margin-left: $cart-content-margin-left;
    }

    .msc-promo-code {
        &__input-box {
            height: $cart-promo-code-input-height;
        }
    }

    .msc-cart-lines-group {
        flex: 1;
        margin-right: $cart-cartline-wrapper-margin-right;
        padding-top: $cart-cartline-wrapper-grp-padding;
    }

    .msc-cart-lines-group-wraper {
        background-color: $msv-white;
        flex: 1;
        margin-bottom: $cart-cartline-wrapper-margin-bottom;

        .msc-cart-line-group__extra-actions {
            display: flex;
            margin-left: $cart-line-group-extra-action-margin-left;
            margin-top: $cart-line-group-extra-action-margin-top;

            .msc-tooltip {
                opacity: 1;

                .msc-tooltip-inner {
                    background-color: $msv-white;
                    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.12), 0 0 1px rgba(0, 0, 0, 0.14);
                    color: $msv-black;
                    font-size: 14px;
                    line-height: 16px;
                    padding: 12px;
                    text-align: left;
                }

                .msc-arrow::before {
                    border-top-color: $msv-white;
                }
            }
        }
    }

    .wrapping-line .msc-cart-line__product-quantity{
        display: none;
    }

    .msc-cart-line__giftwrapping, .msc-cart-line__giftcard{
        box-sizing: border-box;
        margin-top: 20px;
        max-width: 95vw;

        .msc-cart-line__giftwrapping-container{
            box-shadow: 0 0 10px rgba(0,0,0,0.5);
            border-radius: 20px;
            padding: 10px 20px;
        }

        .msc-cart-line__papergiftcard-container{
            display: flex;
            flex-direction: column;
            justify-content: right;
            align-items: center;
        }

        .giftwrapping_title{
            line-height: 2;
        }

        .giftwrapping_variants-container{
            display: flex;
            max-width: 100%;
            overflow-x: scroll;

            &::-webkit-scrollbar {
                width: 10px;
                background-color: #F5F5F5;
            }

            &::-webkit-scrollbar-track {
                border-radius: 10px;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
                box-shadow: inset 0 0 6px rgba(0,0,0,.3);
                background-color: #d1d1d1;
            }
        }

        .giftwrapping_variant{
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            width: 200px;
            height: 210px;
            border-radius: 15px;
        }

        .giftwrapping_variant-details{
            text-align: center;
        }

        .giftwrapping_variant-price{
            padding: 5px 0px;
        }

        .giftwrapping_variant-image{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 200px;
            height: 200px;
        }

        img.giftwrapping_variant-image{
            max-width: 150px;
            max-height: 150px;
        }

        .selected-wrapping{
            background-color: #e3e3e3;
        }

        .ms-account-profile__toggle{
            align-items: baseline;
            justify-content: space-between;
            width: 100%;
            flex-direction: row;
        }

        .ms-account-profile__toggle{
            &-enable {
                span.ms-account-profile__toggle-enable-text,
                button.ms-account-profile__toggle-button{
                    color: #004ebc;
                }
                span.ms-account-profile__toggle-disable-text{
                    color: #545454;
                }
            }
            &-disable {
                span.ms-account-profile__toggle-disable-text{
                    color: #004ebc;
                }
                span.ms-account-profile__toggle-enable-text,
                button.ms-account-profile__toggle-button{
                    color: #545454;
                }
            }
        }
    }

    .msc-cart-line__giftcard {

        .cartline-giftcard {
            width: 55%;
            min-width: 350px;
        }

        .giftcard_input-group-line {
            display: flex;
        }
        .giftcard_input{
            flex: 1;
            padding: 5px;
        }
        textarea.form-control {
            height: auto;
        }
        .giftcard_file_input, .giftcard_actions {
            display: inline-block;
            margin-top: 15px;
            .input-file, .action-button {
                position: relative;
                display: inline-block;
                span {
                    position: relative;
                    display: inline-block;
                    cursor: pointer;
                    outline: none;
                    text-decoration: none;
                    font-size: 14px;
                    vertical-align: middle;
                    color: rgb(255,255,255);
                    text-align: center;
                    background-color: #004ebc;
                    line-height: 22px;
                    height: 40px;
                    padding: 10px 20px;
                    box-sizing: border-box;
                    border: none;
                    margin: 0;
                    transition: background-color 0.2s;
                }
                input[type=file] {
                    position: absolute;
                    z-index: -1;
                    opacity: 0;
                    display: block;
                    width: 0;
                    height: 0;
                }
                 /* Focus */
                input[type=file]:focus + span {
                    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
                }
                /* Hover/active */
                &:hover span {
                    background-color: #2289e3;
                }
                &:active span {
                    background-color: #0043a0;
                }
                /* Disabled */
                input[type=file]:disabled + span {
                    background-color: #eee;
                }
            }
             
            /* Список c превью */
            .input-file-list {
                padding-top: 10px;
            }
            .input-file-list-item {
                display: inline-block;
                //margin: 0 15px 15px;
                width: 150px;
                vertical-align: top;
                position: relative;
            }
            .input-file-list-item img {
                width: 150px;
            }
            .input-file-list-name {
                text-align: center;
                display: block;
                font-size: 12px;
                text-overflow: ellipsis;
                overflow: hidden;
            }
            .input-file-list-remove {
                font-size: 20px;
                color: #fff;
                text-decoration: none;
                display: inline-block;
                position: absolute;
                padding: 0;
                margin: 0;
                top: 5px;
                right: 5px;
                width: 16px;
                height: 16px;
                text-align: center;
                line-height: 16px;
                border-radius: 50%;
            }
        }

        .giftcard_actions{
            display: flex;
            justify-content: space-between;
            .giftcard_input{
                flex: none;
            }
        }

        .giftcard_error-message{
            color: red;
        }
    }
}

@media screen and (max-width: $msv-breakpoint-m) {
    .ms-cart {
        display: block;

        .msc-cartline-wraper .msc-cart-lines-group-wraper {
            margin-right: 0;
            width: 100%;
        }

        .msc-cart-lines-group {
            margin-right: 0;
        }

        .msc-cart-lines-item > .msc-cart-line__add-to-wishlist,
        .msc-cart-lines-item > .msc-cart-line__remove-item {
            margin-left: $cart-cartline-item-content-margin-left-m;
        }

        .msc-cart-lines-item {
            .msc-cart-line {
                &__product-image {
                    @include image($cart-empty-image-width-m);
                }

                &__invoice-image {
                    @include invoice-image($cart-empty-image-width-m);
                }

                &__content {
                    display: block;
                    margin-left: $cart-cartline-item-content-margin-left-m;
                }

                &__bopis-container,
                &__extra-actions,
                &__eid {
                    margin-left: $cart-cartline-item-content-margin-left-m;
                }

                &__giftwrapping {
                    padding-left: 0;
                }

                &__product {
                    padding-bottom: $cart-cartline-line-product-padding-bottom;

                    &-quantity {
                        padding-bottom: $cart-cartline-line-product-padding-bottom;
                    }

                    &-savings {
                        margin-left: 0;
                        text-align: left;
                    }

                    &-price {
                        margin-left: $cart-cartline-line-product-price-margin-left;
                        padding-top: $cart-cartline-line-product-price-padding-top;
                        text-align: left;
                    }
                }

                &-group {
                    &__extra-actions {
                        margin-left: $cart-cartline-item-content-margin-left-m;
                        margin-top: $cart-line-group-extra-action-margin-top;
                    }
                }
            }
        }

        .msc-promo-code {
            &__input-box {
                width: $cart-promo-code-input-width-m;
            }

            &__apply-btn {
                min-width: auto;
            }
        }

        .msc-order-summary-wrapper {
            padding: $cart-order-summary-padding-m;
            width: 100%;
        }

        .msc-order-summary__items {
            @include font-content-s();
        }
    }
}

[dir="rtl"] {
    .ms-cart {
        .ms-account-profile-giftwrapping,
        .msc-cart-line__bopis-container{
            direction: rtl;
        }

        .msc-cart-lines-group-wraper__bopis-heading{
            direction: rtl;
            .msc-cart-lines-group-wraper__bopis-heading-shipping,
            .msc-cart-lines-group-wraper__bopis-heading-pickup,
            .msc-cart-lines-group-wraper__bopis-heading-title{
                margin-right: 76px;
            }
        }

        .msc-cart-line__product-inventory-label{
            display: none;
        }
    }
}
